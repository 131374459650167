import { createRouter } from 'react-chicane';

const ROUTES: any = ['root', 'login', 'admin', 'about'];

export const useRouter = () => Router.useRoute(ROUTES);

export const Router = createRouter({
  root: '/',
  login: '/login',
  admin: '/admin',
  about: '/about',
});

export const reloader = (path: string) => (window.location.href = path);
