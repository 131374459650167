import { Button, Center, PasswordInput, TextInput, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showError } from '../Utilities/_core';
import { useLoginService } from '../Services/loginService';
import { IconCircleX, IconX } from '@tabler/icons-react';

// eslint-disable-next-line
export default () => {
  const { loading, onLoginAsync } = useLoginService();
  const form = useForm({
    initialValues: { email: '', password: '' },
    validateInputOnBlur: ['email', 'password'],
    validate: {
      email: (value) => (value ? null : 'Please enter email!'),
      password: (value) => (value ? null : 'Please enter password!'),
    },
  });

  const onLogin = async (user: any) => {
    const login = await onLoginAsync(user);
    if (login && login.id && login.role) {
      window.location.href = '/admin';
    } else {
      showError('Email Address and Password are invalid!', <IconX />);
    }
  };

  return (
    <div className="login__section services__section--bg2 section--padding">
      <div className="container">
        <form onSubmit={form.onSubmit(onLogin)}>
          <div className="login__section--inner">
            <Center>
              <div className="account__login">
                <div className="account__login--header mb-25">
                  <Title order={2} mb={10} c="#CE3F77">
                    Admin Login
                  </Title>
                  <p className="account__login--header__desc">
                    Login into the Krushi admin account with registered details.
                  </p>
                </div>
                <div className="account__login--inner">
                  <TextInput
                    mb={20}
                    size="lg"
                    type="email"
                    placeholder="Email Address"
                    {...form.getInputProps('email')}
                  />
                  <PasswordInput
                    mb={20}
                    size="lg"
                    placeholder="Password"
                    {...form.getInputProps('password')}
                  />
                  <div className="account__login--remember__forgot mb-15 d-flex justify-content-between align-items-center">
                    <div className="account__login--remember position__relative">
                      <input
                        className="checkout__checkbox--input"
                        id="check1"
                        type="checkbox"
                        defaultChecked
                      />
                      <span className="checkout__checkbox--checkmark"></span>
                      <label
                        className="checkout__checkbox--label login__remember--label"
                        htmlFor="check1"
                      >
                        Remember me
                      </label>
                    </div>
                    {false && (
                      <button className="account__login--forgot" type="submit">
                        Forgot Your Password?
                      </button>
                    )}
                  </div>
                  <Button
                    size="lg"
                    fullWidth
                    type="submit"
                    loading={loading}
                    color="#CE3F77"
                    className="secondary__btn"
                  >
                    Login
                  </Button>
                </div>
              </div>
            </Center>
          </div>
        </form>
      </div>
    </div>
  );
};
