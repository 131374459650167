import { List, ThemeIcon, rem } from '@mantine/core';
import { IconCircleCheck } from '@tabler/icons-react';
import { scrollToSection } from '../Utilities/_core';
import { useStateProvider } from '../Providers/State';

// eslint-disable-next-line
export default () => {
  const { state, setState } = useStateProvider();

  const ListIcon = () => (
    <ThemeIcon color="#CE3F77" size={24} radius="xl">
      <IconCircleCheck style={{ width: rem(16), height: rem(16) }} />
    </ThemeIcon>
  );

  const onClickServiceCategory = (categoryId: string) => {
    setState({ filter: { type: 'category', value: categoryId }, page: 1 });
    scrollToSection('products');
  };

  return (
    <section
      className="services__section services__section--bg1 section--padding"
      id="services"
    >
      <div className="container">
        <div className="section__heading text-center mb-50">
          <h2 className="section__heading--maintitle text-white mb-10">
            Our Professional Service
          </h2>
          <p className="section__heading--desc text-white">
            We are unwavering in our commitment to delivering top-quality
            products. Our hand-embroidery and craftsmanship adhere to the
            highest standards, ensuring that every piece is a work of art.
          </p>
        </div>
        <div className="services__inner">
          <div className="row row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-2 mb--n30">
            <div className="col custom-col mb-30">
              <article className="services__card border-radius-medium">
                <a
                  className="services__card--link"
                  onClick={() =>
                    onClickServiceCategory(
                      'dcc6c2f1-28cc-40d0-8e2c-8cd32982104f'
                    )
                  }
                >
                  <div className="services__card--topbar d-flex justify-content-between">
                    <div className="services__card--number">
                      <span className="services__card--number__text">1</span>
                    </div>
                  </div>

                  <div className="services__card--content">
                    <h3 className="services__card--maintitle mb-15">
                      Bridal Sarees & Blouses
                    </h3>
                    <div className="services__card--desc mb-15">
                      <List spacing="xs" size="sm" icon={<ListIcon />}>
                        <List.Item>Hand-Embroidered Bridal Sarees</List.Item>
                        <List.Item>Traditional Bridal Sarees</List.Item>
                        <List.Item>Modern Fusion Bridal Sarees</List.Item>
                      </List>
                    </div>
                  </div>
                </a>
              </article>
            </div>
            <div className="col custom-col mb-30">
              <article className="services__card border-radius-medium">
                <a
                  className="services__card--link"
                  onClick={() =>
                    onClickServiceCategory(
                      '9785925c-f614-44a0-a364-517b211166d0'
                    )
                  }
                >
                  <div className="services__card--topbar d-flex justify-content-between">
                    <div className="services__card--number">
                      <span className="services__card--number__text">2</span>
                    </div>
                  </div>
                  <div className="services__card--content">
                    <h3 className="services__card--maintitle mb-15">
                      Bridal Lehengas
                    </h3>
                    <div className="services__card--desc mb-15">
                      <List spacing="xs" size="sm" icon={<ListIcon />}>
                        <List.Item>
                          Traditional & Fusion Bridal Lehengas
                        </List.Item>
                        <List.Item>Indo-Western Bridal Lehengas</List.Item>
                        <List.Item>Custom-designed Bridal Lehengas</List.Item>
                      </List>
                    </div>
                  </div>
                </a>
              </article>
            </div>
            <div className="col custom-col mb-30">
              <article className="services__card border-radius-medium">
                <a
                  className="services__card--link"
                  onClick={() =>
                    onClickServiceCategory(
                      'e4c64ec4-9e88-43e8-8e7a-afbb092c7757'
                    )
                  }
                >
                  <div className="services__card--topbar d-flex justify-content-between">
                    <div className="services__card--number">
                      <span className="services__card--number__text">3</span>
                    </div>
                  </div>
                  <div className="services__card--content">
                    <h3 className="services__card--maintitle mb-15">
                      Hand-Embroidered Blouses
                    </h3>
                    <div className="services__card--desc mb-15">
                      <List spacing="xs" size="sm" icon={<ListIcon />}>
                        <List.Item>Blouses with Hand Embroidery</List.Item>
                        <List.Item>Contemporary Fusion Blouses</List.Item>
                        <List.Item>
                          Custom-Designed Embroidered Blouses
                        </List.Item>
                      </List>
                    </div>
                  </div>
                </a>
              </article>
            </div>
            <div className="col custom-col mb-30">
              <article className="services__card border-radius-medium">
                <a
                  className="services__card--link"
                  onClick={() =>
                    onClickServiceCategory(
                      'd4c0d146-8010-4fd3-9272-0a05846bf172'
                    )
                  }
                >
                  <div className="services__card--topbar d-flex justify-content-between">
                    <div className="services__card--number">
                      <span className="services__card--number__text">4</span>
                    </div>
                  </div>
                  <div className="services__card--content">
                    <h3 className="services__card--maintitle mb-15">
                      Groom's Attire
                    </h3>
                    <div className="services__card--desc mb-15">
                      <List spacing="xs" size="sm" icon={<ListIcon />}>
                        <List.Item>Hand-Embroidered Sherwanis</List.Item>
                        <List.Item>Custom Groom's Suits</List.Item>
                        <List.Item>Indo-Western Groom's Wear</List.Item>
                      </List>
                    </div>
                  </div>
                </a>
              </article>
            </div>
            <div className="col custom-col mb-30">
              <article className="services__card border-radius-medium">
                <a
                  className="services__card--link"
                  onClick={() =>
                    onClickServiceCategory(
                      'cae96403-c8b8-435e-a2d5-4dc2e174fa6b'
                    )
                  }
                >
                  <div className="services__card--topbar d-flex justify-content-between">
                    <div className="services__card--number">
                      <span className="services__card--number__text">5</span>
                    </div>
                  </div>
                  <div className="services__card--content">
                    <h3 className="services__card--maintitle mb-15">
                      Anarkalis and Gowns
                    </h3>
                    <div className="services__card--desc mb-15">
                      <List spacing="xs" size="sm" icon={<ListIcon />}>
                        <List.Item>Traditional Anarkalis</List.Item>
                        <List.Item>Fusion Anarkalis</List.Item>
                        <List.Item>Gowns with Hand Embroidery</List.Item>
                      </List>
                    </div>
                  </div>
                </a>
              </article>
            </div>
            <div className="col custom-col mb-30">
              <article className="services__card border-radius-medium">
                <a
                  className="services__card--link"
                  onClick={() =>
                    onClickServiceCategory(
                      '1c2784ab-cbe3-4c9d-83f6-ea970a477324'
                    )
                  }
                >
                  <div className="services__card--topbar d-flex justify-content-between">
                    <div className="services__card--number">
                      <span className="services__card--number__text">6</span>
                    </div>
                  </div>
                  <div className="services__card--content">
                    <h3 className="services__card--maintitle mb-15">
                      Occasion Wear
                    </h3>
                    <div className="services__card--desc mb-15">
                      <List spacing="xs" size="sm" icon={<ListIcon />}>
                        <List.Item>Party Wear Lehengas</List.Item>
                        <List.Item>Wedding Guest Outfits</List.Item>
                        <List.Item>Festive and Special Occasion Wear</List.Item>
                      </List>
                    </div>
                  </div>
                </a>
              </article>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
