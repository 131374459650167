import {
  IconCategory,
  IconChevronRight,
  IconCurrencyRupee,
  IconSearch,
} from '@tabler/icons-react';
import { useShallowEffect } from '@mantine/hooks';
import { onCallbackAsync, scrollToSection } from '../Utilities/_core';
import { useApiService } from '../Services/apiService';
import {
  ActionIcon,
  Button,
  Center,
  Divider,
  Flex,
  Loader,
  NumberInput,
  TextInput,
} from '@mantine/core';
import { useState } from 'react';
import $ from 'jquery';
import { useStateProvider } from '../Providers/State';
import { IconMinus } from '@tabler/icons-react';
import { useForm } from '@mantine/form';

// eslint-disable-next-line
export default (props: any) => {
  const { state, setState } = useStateProvider();
  const [categoryActive, setCategory] = useState('');
  const [priceRangeActive, setPriceRange] = useState('');
  const { onGets, loading } = useApiService('/api/records/category_master');

  useShallowEffect(() => {
    onCallbackAsync(async () => {
      const response = await onGets();
      if (response && response.length > 0) {
        setState({
          categories: response?.sort((a: any, b: any) =>
            a.name.localeCompare(b.name)
          ),
        });
      }
    });
  }, []);

  const formSearch = useForm({
    initialValues: { text: '' },
    validate: {
      text: (value) => (value ? null : 'Please enter some product name!'),
    },
  });

  const formPrice = useForm({
    initialValues: { min: 1000, max: 5000 },
  });

  $('input[type="checkbox"]').on('change', function () {
    if (this.id.includes('filter-check')) {
      $('input[type="checkbox"]').not(this).prop('checked', false);
    }
  });

  const onOpenCategoryModal = () => {
    const modal = document.getElementById('category-modal');
    modal && modal.classList.add('is-visible');
  };

  const onCloseSidebarJS = () => {
    $('body').removeClass('offcanvas__filter--sidebar_active');
    $('.offcanvas__filter--sidebar.widget__area').removeClass('active');
  };

  const onClickSearch = (search: any) => {
    onCloseSidebarJS();
    setState({ filter: { type: 'search', value: search.text }, page: 1 });
  };

  const onClickCategory = (category: any) => {
    if (categoryActive === category.name) {
      setState({ filter: { type: '', value: '' }, page: 1 });
    } else {
      setState({ filter: { type: 'category', value: category.id }, page: 1 });
    }
    onCloseSidebarJS();
    setCategory(categoryActive === category.name ? '' : category.name);
  };

  const onClickPrice = (price: any) => {
    $('.price-error-text').addClass('hide');
    const range = `${price.min - 1}-${price.max + 1}`;
    if (price.min > price.max) {
      $('.price-error-text').removeClass('hide');
    } else {
      onCloseSidebarJS();
      scrollToSection('products-admin');
      setState({ filter: { type: 'price', value: range }, page: 1 });
    }
  };

  const onClickRange = (name: string, min: number, max: number) => {
    const range = `${min - 1}-${max + 1}`;
    if (priceRangeActive === `${min}-${max}`) {
      setState({ filter: { type: '', value: '' }, page: 1 });
    } else {
      const type = name !== 'Below' ? 'range' : 'price';
      setState({ filter: { type, value: range }, page: 1 });
    }
    onCloseSidebarJS();
    scrollToSection('products-admin');
    setPriceRange(priceRangeActive === `${min}-${max}` ? '' : `${min}-${max}`);
  };

  const CategoryList = (props: any) => (
    <ul className="widget__categories--menu">
      {props?.categories?.map((category: any, index: number) => {
        return (
          <li
            style={{
              color: category.name === categoryActive ? '#CE3F77' : 'black',
            }}
            key={index}
            className="widget__categories--menu__list"
            onClick={() => onClickCategory(category)}
          >
            <label className="widget__categories--menu__label d-flex align-items-center">
              <IconCategory className="widget__categories--menu__img" />
              <span className="widget__categories--menu__text">
                {category.name}
              </span>
              <IconChevronRight
                size={16}
                className="widget__categories--menu__arrowdown--icon"
              />
            </label>
          </li>
        );
      })}
    </ul>
  );

  return (
    <>
      <div className="single__widget widget__bg bg__gray--color">
        <h2 className="widget__title position__relative h3">
          Filter By Search
        </h2>
        <form
          className="widget__search--form"
          onSubmit={formSearch.onSubmit(onClickSearch)}
        >
          <TextInput
            size="md"
            placeholder="search with product"
            rightSection={
              <ActionIcon
                size={42}
                color="#CE3F77"
                variant="filled"
                type="submit"
                className="secondary__btn"
              >
                <IconSearch size={28} stroke={1.5} />
              </ActionIcon>
            }
            {...formSearch.getInputProps('text')}
          />
        </form>
      </div>
      <div className="single__widget widget__bg bg__gray--color">
        <div className="mb-20">
          <h2 className="widget__title position__relative h3">
            Filter By Categories
          </h2>
          {props.show && (
            <Flex justify="space-between">
              <Button
                size="xs"
                color="#CE3F77"
                type="submit"
                onClick={onOpenCategoryModal}
                className="secondary__btn"
              >
                Add / Edit
              </Button>
              <Divider my="md" />
            </Flex>
          )}
        </div>
        {loading && (
          <Center>
            <Loader color="#CE3F77" />
          </Center>
        )}
        {!loading && <CategoryList categories={state?.categories} />}
      </div>
      <div className="single__widget widget__bg bg__gray--color">
        <h2 className="widget__title position__relative h3">Filter By Price</h2>
        <form
          className="price__filter--form"
          onSubmit={formPrice.onSubmit(onClickPrice)}
        >
          <div className="price__filter--form__inner mb-15 d-flex align-items-center">
            <div className="price__filter--group">
              <label
                className="price__filter--label"
                htmlFor="Filter-Price-GTE1"
              >
                From
              </label>
              <NumberInput
                prefix="₹"
                defaultValue={5000}
                size="md"
                min={1}
                max={999999}
                {...formPrice.getInputProps('min')}
              />
            </div>
            <div className="price__divider">
              <IconMinus size={12} />
            </div>
            <div className="price__filter--group">
              <label
                className="price__filter--label"
                htmlFor="Filter-Price-LTE1"
              >
                To
              </label>
              <NumberInput
                prefix="₹"
                defaultValue={10000}
                size="md"
                min={1}
                max={999999}
                {...formPrice.getInputProps('max')}
              />
            </div>
          </div>
          <p className="price-error-text text-red-500 font-lg hide">
            From must be less than To value!
          </p>
          <Button
            size="md"
            color="#CE3F77"
            type="submit"
            className="secondary__btn"
          >
            Submit
          </Button>
        </form>
      </div>
      <div className="single__widget widget__bg bg__gray--color">
        <h2 className="widget__title position__relative h3">Filter By Range</h2>
        <ul className="widget__form--check">
          <li
            className="widget__form--check__list"
            style={{
              color: priceRangeActive === '2-10000' ? '#CE3F77' : 'black',
            }}
          >
            <label
              className="widget__form--check__label"
              htmlFor="check1"
              onClick={() => onClickRange('Below', 2, 5000)}
            >
              Below <IconCurrencyRupee size={14} />
              5,000
            </label>
            <input
              className="widget__form--check__input"
              id="filter-check1"
              type="checkbox"
              checked={priceRangeActive === '2-5000'}
            />
            <span className="widget__form--checkmark"></span>
          </li>
          <li
            className="widget__form--check__list"
            style={{
              color: priceRangeActive === '5000-10000' ? '#CE3F77' : 'black',
            }}
          >
            <label
              className="widget__form--check__label"
              htmlFor="check2"
              onClick={() => onClickRange('Between', 5000, 10000)}
            >
              Between <IconCurrencyRupee size={14} />
              5,000 - <IconCurrencyRupee size={14} />
              10,000
            </label>
            <input
              className="widget__form--check__input"
              id="filter-check2"
              type="checkbox"
              checked={priceRangeActive === '5000-10000'}
            />
            <span className="widget__form--checkmark"></span>
          </li>
          <li
            className="widget__form--check__list"
            style={{
              color: priceRangeActive === '10000-15000' ? '#CE3F77' : 'black',
            }}
          >
            <label
              className="widget__form--check__label"
              htmlFor="check3"
              onClick={() => onClickRange('Between', 10000, 15000)}
            >
              Between <IconCurrencyRupee size={14} />
              10,000 - <IconCurrencyRupee size={14} />
              15,000
            </label>
            <input
              className="widget__form--check__input"
              id="filter-check3"
              type="checkbox"
              checked={priceRangeActive === '10000-15000'}
            />
            <span className="widget__form--checkmark"></span>
          </li>
          <li
            className="widget__form--check__list"
            style={{
              color: priceRangeActive === '15000-20000' ? '#CE3F77' : 'black',
            }}
          >
            <label
              className="widget__form--check__label"
              htmlFor="check3"
              onClick={() => onClickRange('Between', 15000, 20000)}
            >
              Between <IconCurrencyRupee size={14} />
              15,000 - <IconCurrencyRupee size={14} />
              20,000
            </label>
            <input
              className="widget__form--check__input"
              id="filter-check3"
              type="checkbox"
              checked={priceRangeActive === '15000-20000'}
            />
            <span className="widget__form--checkmark"></span>
          </li>
          <li
            className="widget__form--check__list"
            style={{
              color: priceRangeActive === '20000-100000' ? '#CE3F77' : 'black',
            }}
          >
            <label
              className="widget__form--check__label"
              htmlFor="check4"
              onClick={() => onClickRange('Above', 20000, 100000)}
            >
              Above <IconCurrencyRupee size={14} />
              20,000
            </label>
            <input
              className="widget__form--check__input"
              id="filter-check4"
              type="checkbox"
              checked={priceRangeActive === '20000-100000'}
            />
            <span className="widget__form--checkmark"></span>
          </li>
        </ul>
        <div className="h-24"></div>
      </div>
    </>
  );
};
