import { Button, Center, Title } from '@mantine/core';
import { scrollToSection } from '../Utilities/_core';
import { useViewportSize } from '@mantine/hooks';

// eslint-disable-next-line
export default (props: any) => {
  const { width } = useViewportSize();
  return (
    <section
      style={{ height: '850px' }}
      className="hero__slider--section bg-scheme-2"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div
              className="slider__section--bg3 mt-10"
              style={{
                marginLeft: width < 1025 ? '' : '-15rem',
              }}
            ></div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div
              style={{
                marginTop: width < 1025 ? '4rem' : '12rem',
              }}
              className="slider__content style3"
            >
              <h1 className="slider__content--maintitle style3 text-yellow-500 text-center">
                Best in Bridal Designers, Hand Embroidery, Stitching & Blouses
              </h1>
              <Center>
                <p className="slider__content--desc style3 text-white text-center">
                  Celebrating the Artistry of Hand-Embroidered Indian Bridal and
                  Groom Wear. We hold Indian traditions and heritage close to
                  our hearts. Our designs are a tribute to the timeless beauty
                  of Indian bridal and groom wear.
                </p>
              </Center>
              <div className="slider__content--footer style3 text-center">
                <Button
                  size="lg"
                  color="#000441"
                  className="hover:bg-yellow-500"
                  onClick={() => scrollToSection('products')}
                >
                  Search Products
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
