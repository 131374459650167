import Filters from '../Components/Filters';

// eslint-disable-next-line
export default (props: any) => {
  return (
    <div className="offcanvas__filter--sidebar widget__area">
      <button
        type="button"
        className="offcanvas__filter--close"
        data-offcanvas=""
      >
        <svg
          className="minicart__close--icon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            fill="currentColor"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="32"
            d="M368 368L144 144M368 144L144 368"
          ></path>
        </svg>
        <span className="offcanvas__filter--close__text">Close</span>
      </button>
      <div className="offcanvas__filter--sidebar__inner">
        <Filters isAdmin={props.isAdmin} />
      </div>
    </div>
  );
};
