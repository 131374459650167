//#region
import Footer from '../Sections/Footer';
import Header from '../Sections/Header';
import Banner from '../Components/Banner';
import Navbar from '../Components/Navbar';
import Scripts from '../Components/Scripts';
import Sections from '../Components/Sections';
import LoginForm from '../Components/LoginForm';
import { useDocumentTitle, useShallowEffect } from '@mantine/hooks';
import { useSession } from '../Utilities/_core';
//#endregion

// eslint-disable-next-line
export default () => {
  useDocumentTitle('Krushi');
  const [_, setToken] = useSession('app_user', null);

  useShallowEffect(() => setToken(null), []);

  return (
    <>
      <Header>
        <Banner />
        <Navbar loggedOut />
      </Header>
      <Sections>
        <LoginForm />
      </Sections>
      <Footer />
      <Scripts />
    </>
  );
};
