import { Helmet } from 'react-helmet';
import $ from 'jquery';
import { scrollToSection } from '../Utilities/_core';

// eslint-disable-next-line
export default () => {
  // var url = new URL(window.location.href);
  // var section = url.searchParams.get('section');
  // if (section) {
  //   window.location.href = `#${section}`;
  // }
  // section && scrollToSection(section);
  // var position = $(`#${section}`).offset()?.top;
  // $('html, body').animate({
  //   scrollTop: position,
  // });

  if (window.location.hash) window.location.href = window.location.hash;

  return (
    <Helmet>
      <script src="/assets/js/script.js" defer></script>
    </Helmet>
  );
};
