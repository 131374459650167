import {
  TextInput,
  Group,
  Text,
  rem,
  Title,
  Button,
  Select,
  Textarea,
  Checkbox,
  NumberInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconCheck, IconPhoto, IconX } from '@tabler/icons-react';
import { IconTrash } from '@tabler/icons-react';
import { useAxios } from '../Utilities/axios';
import { useStateProvider } from '../Providers/State';
import { Dropzone, FileWithPath, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { useState } from 'react';
import { useShallowEffect } from '@mantine/hooks';
import { showError, showSuccess } from '../Utilities/_core';

// eslint-disable-next-line
export default (props: any) => {
  const [file, setFile] = useState('');
  const { loading, post, put } = useAxios();
  const { state, setState } = useStateProvider();
  const [files, setFiles] = useState<FileWithPath[]>([]);

  const onCloseProductModal = () => {
    setState({ product: {} });
    const modal = document.getElementById('product-modal');
    modal && modal.classList.remove('is-visible');
  };

  const onCategoriesList: any = state?.categories?.map((category: any) => ({
    value: category.id,
    label: category.name,
  }));

  const onDeleteProductImage = () => {
    setFile('');
    setFiles([]);
  };

  const onSubmitProductAsync = async (data: any) => {
    let productRes = undefined;
    const file = new FormData();
    file.append('file', files[0]);
    if (state?.product?.id) {
      productRes = await put({
        data,
        url: `/api/records/product_master/${state?.product?.id}`,
      });
    } else {
      data['id'] = URL.createObjectURL(new Blob([])).slice(-36); // UUID
      productRes = await post({ data, url: `/api/records/product_master` });
    }
    if (productRes > 0 || productRes === data['id']) {
      await post({
        data: file,
        url: `/file/upload/${
          state?.product?.id ? state?.product?.id : data['id']
        }`,
      });
      showSuccess(
        `Product has been ${
          state?.product?.id ? 'edited' : 'added'
        } successfully!`,
        <IconCheck />
      );
      setTimeout(() => {
        window.location.href = '/admin/#products';
      }, 1000);
      onCloseProductModal();
    } else {
      showError('Something went wrong, please try later!', <IconX />);
    }
  };

  const initialValues = {
    category_master_id: state?.product?.category_master_id,
    name: state?.product?.name,
    mrp_price: state?.product?.mrp_price,
    sale_price: state?.product?.sale_price,
    is_latest: state?.product?.is_latest,
    is_trending: state?.product?.is_trending,
    is_deleted: state?.product?.is_deleted,
    description: state?.product?.description,
    rating: 5,
  };

  const form = useForm({
    initialValues,
    validateInputOnBlur: [
      'category_master_id',
      'name',
      'mrp_price',
      'sale_price',
    ],
    validate: {
      name: (value) => (value ? null : 'Please enter product!'),
      mrp_price: (value) => (value ? null : 'Please enter To price!'),
      sale_price: (value) => (value ? null : 'Please enter From price!'),
      category_master_id: (value) => (value ? null : 'Please select category!'),
    },
  });

  useShallowEffect(() => {
    form.setValues(initialValues);
    form.resetDirty(initialValues);
    if (state?.product?.image_master) {
      const image = state?.product?.image_master?.sort(
        (a: any, b: any) =>
          Number(new Date(b.created_at)) - Number(new Date(a.created_at))
      )[0];
      setFile(image?.source);
    } else {
      const fileURL = files?.map((file: any) => URL.createObjectURL(file))[0];
      setFile(fileURL);
    }
  }, [state?.product]);

  return (
    <div className="modal" id="product-modal" data-animation="slideInUp">
      <div className="modal-dialog quickview__main--wrapper">
        <header className="modal-header quickview__header">
          <button
            className="close-modal quickview__close--btn"
            aria-label="close modal"
            onClick={onCloseProductModal}
          >
            ✕
          </button>
        </header>
        <div className="quickview__inner">
          <form onSubmit={form.onSubmit(onSubmitProductAsync)}>
            <div className="row row-cols-lg-2 row-cols-md-2">
              <div className="col">
                <div className="quickview__gallery">
                  <div className="product__media--right__horizontal">
                    <div className="product__media--preview__horizontal">
                      <div className="product__media--preview__items">
                        <Title order={2} mb={14} mt={-12} c="#CE3F77">
                          {state?.product?.id ? 'Edit' : 'Add'} Product
                        </Title>
                        {!file ? (
                          <Dropzone
                            style={{ height: '360px' }}
                            onDrop={(files: any) => {
                              setFiles(files);
                              const fileURL = files?.map((file: any) =>
                                URL.createObjectURL(file)
                              )[0];
                              setFile(fileURL);
                            }}
                            maxSize={3 * 1024 ** 2}
                            accept={IMAGE_MIME_TYPE}
                            {...props}
                          >
                            <Group
                              justify="center"
                              gap="xl"
                              mih={220}
                              style={{ pointerEvents: 'none' }}
                            >
                              <Dropzone.Idle>
                                <IconPhoto
                                  style={{
                                    width: rem(100),
                                    height: rem(100),
                                    color: 'var(--mantine-color-dimmed)',
                                  }}
                                  stroke={1.5}
                                />
                              </Dropzone.Idle>
                              <div className="text-center">
                                <Text size="xl" inline>
                                  Drag image here or click to select image
                                </Text>
                                <Text size="sm" c="dimmed" inline mt={7}>
                                  Attach only one image file, should not exceed
                                  5MB.
                                </Text>
                              </div>
                            </Group>
                          </Dropzone>
                        ) : (
                          <>
                            <div className="product__media--preview__items--link glightbox">
                              <img
                                src={file}
                                alt="product-media-img"
                                style={{ width: '400px', height: '360px' }}
                                className="product__media--preview__items--img"
                              />
                            </div>
                            <div className="product__media--view__icon">
                              <a
                                className="product__media--view__icon--link glightbox"
                                onClick={onDeleteProductImage}
                              >
                                <IconTrash />
                                <span className="visually-hidden">
                                  Media Gallery
                                </span>
                              </a>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-128"></div>
              </div>
              <div className="col">
                <div style={{ marginTop: '-1rem' }}></div>
                <div className="quickview__info d-lg-mt-5">
                  <Select
                    mb={10}
                    size="lg"
                    withCheckIcon={false}
                    data={onCategoriesList}
                    placeholder="Category"
                    {...form.getInputProps('category_master_id')}
                  />
                  <TextInput
                    mb={10}
                    size="lg"
                    id="name"
                    type="text"
                    placeholder="Product"
                    {...form.getInputProps('name')}
                  />
                  <Group grow wrap="nowrap">
                    <NumberInput
                      mb={10}
                      size="lg"
                      maxLength={6}
                      placeholder="From Price"
                      {...form.getInputProps('sale_price')}
                    />
                    <NumberInput
                      mb={10}
                      size="lg"
                      maxLength={6}
                      placeholder="To Price"
                      {...form.getInputProps('mrp_price')}
                    />
                  </Group>
                  <Group grow wrap="nowrap" mt={10} mb={20}>
                    <Checkbox
                      color="#CE3F77"
                      defaultChecked={state?.product?.is_latest}
                      label="New Design"
                      {...form.getInputProps('is_latest')}
                    />
                    <Checkbox
                      color="#CE3F77"
                      defaultChecked={state?.product?.is_trending}
                      label="Trending Now"
                      {...form.getInputProps('is_trending')}
                    />
                  </Group>
                  <Textarea
                    mb={15}
                    size="lg"
                    placeholder="Product Description"
                    {...form.getInputProps('description')}
                  />
                  <Group grow wrap="nowrap">
                    <Button
                      color="gray"
                      variant="filled"
                      onClick={onCloseProductModal}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      loading={loading}
                      variant="filled"
                      color="#CE3F77"
                    >
                      Submit
                    </Button>
                  </Group>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
