import { useShallowEffect } from '@mantine/hooks';
import { useState } from 'react';
import Product from '../Components/Product';
import { onCallbackAsync } from '../Utilities/_core';
import { useAxios } from '../Utilities/axios';
import { Center, Loader } from '@mantine/core';

// eslint-disable-next-line
export default (props: any) => {
  const [products, setProducts] = useState([]);

  const { loading, get } = useAxios(
    '/api/records/product_master?join=image_master&filter=is_deleted,eq,false&filter=is_trending,eq,true'
  );

  useShallowEffect(() => {
    onCallbackAsync(async () => {
      const products = await get({ url: '&order=updated_at,desc&size=4' });
      if (products && products.length > 0) {
        setProducts(products);
      }
    });
  }, []);

  return (
    <section
      className="product__section services__section--bg2 section--padding"
      id="trending"
    >
      <div className="container-fluid">
        <div className="section__heading text-center mb-40">
          <h2 className="section__heading--maintitle text__secondary mb-10">
            Featured Trendings
          </h2>
          <p className="section__heading--desc">
            Bridal dress can be described as elegance wrapping a woman’s persona
            as a whole. For that matter, an outfit oozing divine aesthetics is
            what a bride-to-be needs.
          </p>
        </div>
        <div className="product__inner">
          {loading ? (
            <div style={{ height: '570px' }}>
              <Center>
                <Loader color="#CE3F77" />
              </Center>
            </div>
          ) : (
            <div className="row row-cols-xl-4 row-cols-lg-3 row-cols-md-3 row-cols-2 mb--n30">
              {products?.map((product: any) => (
                <Product.Item
                  product={product}
                  key={product.id}
                  index={product.id}
                  isAdmin={props.isAdmin}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
