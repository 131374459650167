import { Flex, Space, Text } from '@mantine/core';
import { scrollToSection, useSession } from '../Utilities/_core';
import { IconHome, IconLogout } from '@tabler/icons-react';
import { useViewportSize } from '@mantine/hooks';
import { IconUserCircle } from '@tabler/icons-react';

export default (props: any) => {
  const [token, setToken] = useSession('app_user', null);
  const onLogout = () => {
    window.location.href = '/login';
  };
  const { width } = useViewportSize();

  return (
    <div className="main__header position__relative header__sticky">
      <div className={width < 768 ? 'px-5' : 'px-38'}>
        <div className="main__header--inner d-flex justify-content-between align-items-center">
          <div className="offcanvas__header--menu__open"></div>
          <div className="header__menu d-none d-lg-block">
            <nav className="header__menu--navigation">
              <ul className="d-flex">
                <li className="header__menu--items ml-14"></li>
                <li className="header__menu--items"></li>
                <li className="header__menu--items">
                  <a className="header__menu--link" href="/">
                    Home
                  </a>
                </li>
                <li className="header__menu--items">
                  <a className="header__menu--link" href="/#trending">
                    Trending
                  </a>
                </li>
                <li className="header__menu--items mega__menu--items">
                  <a className="header__menu--link" href="/#services">
                    Services
                  </a>
                </li>
                <li className="header__menu--items mega__menu--items">
                  <a className="header__menu--link" href="/#products">
                    Products
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div
            className="main__logo"
            style={{
              marginLeft:
                width === 768
                  ? ''
                  : width < 768
                  ? props.backHome || props.loggedIn
                    ? '1rem'
                    : ''
                  : '2rem',
            }}
          >
            <h1 className="main__logo--title">
              <a className="main__logo--link" href="/">
                <img
                  className="main__logo--img"
                  src="assets/img/logo/logo-white.png"
                  alt="logo-img"
                  width={200}
                />
              </a>
            </h1>
          </div>
          <div className="header__menu d-none d-lg-block">
            <nav className="header__menu--navigation">
              <ul className="d-flex">
                <li className="header__menu--items">
                  <a className="header__menu--link" href="/#ratings">
                    Testimonials
                  </a>
                </li>
                <li className="header__menu--items">
                  <a className="header__menu--link" href="/#contact">
                    Contact Us
                  </a>
                </li>
                <li className="header__menu--items mega__menu--items">
                  <a className="header__menu--link" href="/about">
                    About Us
                  </a>
                </li>
                <li className="header__menu--items">
                  <Flex>
                    {props.loggedIn && (
                      <>
                        <IconUserCircle size={28} color="#CE3F77" />
                        <a
                          className="header__menu--link text-primary"
                          onClick={onLogout}
                        >
                          Logout
                        </a>
                      </>
                    )}
                    {/* {props.loggedOut && (
                      <a
                        className="header__menu--link text-primary"
                        href="/login"
                      >
                        Admin
                      </a>
                    )} */}
                  </Flex>
                </li>
              </ul>
            </nav>
          </div>
          <div className="header__account">
            <div className="header__account--items d-none d-md-block">
              {props.loggedIn && (
                <IconLogout color="#CE3F77" onClick={onLogout} />
              )}
              {props.backHome && (
                <a href="/">
                  <IconHome color="#CE3F77" />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
