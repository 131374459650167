import { useState } from 'react';
import { API_BASE_URL, useSession } from './_core';
import axios, { Method } from 'axios';

interface IUrl {
  url?: any;
}

interface IData extends IUrl {
  data: any;
}

export const useAxios = (path?: string) => {
  const [token, setToken] = useSession('app_user');

  axios.defaults.baseURL = API_BASE_URL;

  if (token) {
    axios.interceptors.request.use((config) => {
      config.headers.authorization = `Basic ${token}`;
      return config;
    });
  }

  axios.interceptors.response.use(
    (res) => {
      const auth = res ? res.headers?.authorization : '';
      if (auth && auth.includes('Basic')) {
        setToken(auth.replace('Basic ', ''));
      }
      return res;
    },
    (err) => err
  );

  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);

  const endUrl = (url?: any) => (path && url ? path + url : url ? url : path);

  const req = async (method: Method, url: string, data?: any) => {
    setLoading(true);
    const res = await axios({ method, url: endUrl(url), data });
    setLoading(false);
    setData(res?.data);
    return res?.data;
  };

  const get = async (opt?: IUrl) => await req('GET', opt?.url);

  const del = async (opt?: IUrl) => await req('DELETE', opt?.url);

  const put = async (opt: IData) => await req('PUT', opt?.url, opt.data);

  const post = async (opt: IData) => await req('POST', opt?.url, opt.data);

  const patch = async (opt: IData) => await req('PATCH', opt?.url, opt.data);

  return { data, loading, axios, get, del, post, put, patch };
};
