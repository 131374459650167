import { Center, Input, Loader } from '@mantine/core';
import { scrollToSection, showError, showSuccess } from '../Utilities/_core';
import { useForm } from '@mantine/form';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useAxios } from '../Utilities/axios';

// eslint-disable-next-line
export default () => {
  const { loading, post } = useAxios('/email/newsletter');

  const initialValues = {
    email: '',
  };

  const form = useForm({
    initialValues,
    validateInputOnBlur: ['email'],
    validate: {
      email: (value) => (value ? null : 'Please enter email!'),
    },
  });

  const onSubmitAsync = async (data: any) => {
    const response = await post({ data });
    if (response) {
      showSuccess('Newsletter subscribed successfully!', <IconCheck />);
    } else {
      showError('Something went wrong, please try later!', <IconX />);
    }
    form.setValues(initialValues);
    form.resetDirty(initialValues);
  };

  return (
    <footer className="footer__section footer__bg">
      <div className="container">
        <div className="main__footer section--padding">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="footer__widget active">
                <div
                  className="footer__widget--inner"
                  style={{ display: 'block', boxSizing: 'border-box' }}
                >
                  <a className="footer__logo" href="/">
                    <img
                      className="border-radius-medium footer__logo--img display-block"
                      src="assets/img/logo/logo-white.png"
                      alt="footer-logo"
                      width={200}
                    />
                  </a>
                  <p className="footer__widget--desc ">
                    Krushi Ladiez Boutique was founded with a deep-rooted
                    passion for Indian craftsmanship and the art of
                    hand-embroidery.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-20">
              <div className="footer__widget">
                <h2 className="footer__widget--title ">
                  Navigations
                  <button
                    className="footer__widget--button"
                    aria-label="footer widget button"
                  ></button>
                  <svg
                    className="footer__widget--title__arrowdown--icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12.355"
                    height="8.394"
                    viewBox="0 0 10.355 6.394"
                  >
                    <path
                      d="M15.138,8.59l-3.961,3.952L7.217,8.59,6,9.807l5.178,5.178,5.178-5.178Z"
                      transform="translate(-6 -8.59)"
                      fill="currentColor"
                    ></path>
                  </svg>
                </h2>
                <ul
                  className="footer__widget--menu footer__widget--inner"
                  style={{ display: 'block', boxSizing: 'border-box' }}
                >
                  <li className="footer__widget--menu__list">
                    <a className="footer__widget--menu__text" href="/#trending">
                      Trending
                    </a>
                  </li>
                  <li className="footer__widget--menu__list">
                    <a className="footer__widget--menu__text" href="/#services">
                      Services
                    </a>
                  </li>
                  <li className="footer__widget--menu__list">
                    <a className="footer__widget--menu__text" href="/#products">
                      Products
                    </a>
                  </li>
                  <li className="footer__widget--menu__list">
                    <a className="footer__widget--menu__text" href="/#ratings">
                      Testimonials
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 mb-20">
              <div className="footer__widget">
                <h2 className="footer__widget--title ">
                  Account Links
                  <button
                    className="footer__widget--button"
                    aria-label="footer widget button"
                  ></button>
                  <svg
                    className="footer__widget--title__arrowdown--icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12.355"
                    height="8.394"
                    viewBox="0 0 10.355 6.394"
                  >
                    <path
                      d="M15.138,8.59l-3.961,3.952L7.217,8.59,6,9.807l5.178,5.178,5.178-5.178Z"
                      transform="translate(-6 -8.59)"
                      fill="currentColor"
                    ></path>
                  </svg>
                </h2>
                <ul
                  className="footer__widget--menu footer__widget--inner"
                  style={{ display: 'block', boxSizing: 'border-box' }}
                >
                  <li className="footer__widget--menu__list">
                    <a className="footer__widget--menu__text" href="/admin">
                      Admin
                    </a>
                  </li>
                  <li className="footer__widget--menu__list">
                    <a className="footer__widget--menu__text" href="/about">
                      About Us
                    </a>
                  </li>
                  <li className="footer__widget--menu__list">
                    <a className="footer__widget--menu__text" href="/#contact">
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="footer__widget">
                <h2 className="footer__widget--title ">
                  Newsletter
                  <button
                    className="footer__widget--button"
                    aria-label="footer widget button"
                  ></button>
                  <svg
                    className="footer__widget--title__arrowdown--icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12.355"
                    height="8.394"
                    viewBox="0 0 10.355 6.394"
                  >
                    <path
                      d="M15.138,8.59l-3.961,3.952L7.217,8.59,6,9.807l5.178,5.178,5.178-5.178Z"
                      transform="translate(-6 -8.59)"
                      fill="currentColor"
                    ></path>
                  </svg>
                </h2>
                <div
                  className="footer__newsletter footer__widget--inner"
                  style={{ display: 'block', boxSizing: 'border-box' }}
                >
                  <p className="footer__newsletter--desc">
                    Get updates by subscribe our newsletter
                  </p>
                  <form
                    className="newsletter__subscribe--form__style position__relative"
                    onSubmit={form.onSubmit(onSubmitAsync)}
                  >
                    <label>
                      <input
                        className="footer__newsletter--input newsletter__subscribe--input"
                        placeholder="Email address"
                        type="email"
                        {...form.getInputProps('email')}
                      />
                    </label>
                    <button
                      className="footer__newsletter--button newsletter__subscribe--button primary__btn"
                      type="submit"
                    >
                      {loading ? (
                        <Center>
                          <Loader color="#ffffff" size={'sm'} />
                        </Center>
                      ) : (
                        <>
                          Subscribe
                          <svg
                            className="newsletter__subscribe--button__icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9.159"
                            height="7.85"
                            viewBox="0 0 9.159 7.85"
                          >
                            <path
                              data-name="Icon material-send"
                              d="M3,12.35l9.154-3.925L3,4.5,3,7.553l6.542.872L3,9.3Z"
                              transform="translate(-3 -4.5)"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </>
                      )}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer__bottom">
          {/*  d-flex justify-content-between align-items-center */}
          <Center>
            <p className="copyright__content m-0">
              Copyright © 2024 by{' '}
              <a href="/">
                <strong> Krushi Ladiez Boutique</strong>
              </a>
              . All Rights Reserved.
            </p>
          </Center>

          {/* <p className="footer__bottom--desc">
            <a href="@">Term &amp; condition</a>, <a href="@">Privacy Policy</a>
          </p> */}
        </div>
      </div>
    </footer>
  );
};
