import { Text } from '@mantine/core';
import { useDocumentTitle } from '@mantine/hooks';

export default () => {
  useDocumentTitle('Krushi');
  return (
    // <CenterView mt={-50}>
    //   <div className="notfound">
    //     <TitleAvatar ml={-30} size={240}>
    //       Not Found
    //     </TitleAvatar>
    //     <Text mt={10}>Requested Teams-On page is not been found!</Text>
    //   </div>
    // </CenterView>
    <></>
  );
};
