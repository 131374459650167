import { useSetState } from '@mantine/hooks';
import { StateContext } from '../Utilities/_core';
import { useContext } from 'react';

export const useStateProvider = () => {
  const { state, setState } = useContext(StateContext) as TState;
  return {
    state,
    setState,
  };
};

export const StateProvider = ({ children }: { children: any }) => {
  const [state, setState] = useSetState<IState>({
    page: 1,
    ratings: [],
    product: {},
    categories: [],
    isLogged: false,
    filter: { type: '', value: '' },
  });
  return (
    <StateContext.Provider value={{ state, setState }}>
      {children}
    </StateContext.Provider>
  );
};
