import { useState } from 'react';
import { Center, Title } from '@mantine/core';
import { useShallowEffect } from '@mantine/hooks';
import { onCallbackAsync } from '../Utilities/_core';
import { useAxios } from '../Utilities/axios';

// eslint-disable-next-line
export default (props: any) => {
  const [isShow, setShow] = useState(props.show);
  const [message, setMessage] = useState(props.message);
  const { get } = useAxios('/health/ping');

  if (props.timeout) {
    setTimeout(() => {
      setShow(false);
    }, props.timeout);
  }

  useShallowEffect(() => {
    onCallbackAsync(async () => {
      const data = await get();
      if (data.db.banner_text) {
        setShow(true);
        setMessage(data.db.banner_text);
      }
    });
  }, []);

  return (
    <>
      {isShow && (
        <div className="header__topbar bg__secondary">
          <div className="container">
            <div className="header__topbar--inner">
              <Center>
                <Title
                  order={4}
                  className="text-yellow-500"
                  style={{ textAlign: 'center' }}
                >
                  {message}
                </Title>
              </Center>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
