import { scrollToSection } from '../Utilities/_core';
import { IconBuildingStore, IconUserCircle } from '@tabler/icons-react';

// eslint-disable-next-line
export default () => {
  return (
    <div className="offcanvas__stikcy--toolbar" tabIndex={-1}>
      <ul className="d-flex justify-content-between">
        <li className="offcanvas__stikcy--toolbar__list">
          <a className="offcanvas__stikcy--toolbar__btn" href="/">
            <span className="offcanvas__stikcy--toolbar__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                width="21.51"
                height="21.443"
                viewBox="0 0 22 17"
              >
                <path
                  fill="currentColor"
                  d="M20.9141 7.93359c.1406.11719.2109.26953.2109.45703 0 .14063-.0469.25782-.1406.35157l-.3516.42187c-.1172.14063-.2578.21094-.4219.21094-.1406 0-.2578-.04688-.3515-.14062l-.9844-.77344V15c0 .3047-.1172.5625-.3516.7734-.2109.2344-.4687.3516-.7734.3516h-4.5c-.3047 0-.5742-.1172-.8086-.3516-.2109-.2109-.3164-.4687-.3164-.7734v-3.6562h-2.25V15c0 .3047-.11719.5625-.35156.7734-.21094.2344-.46875.3516-.77344.3516h-4.5c-.30469 0-.57422-.1172-.80859-.3516-.21094-.2109-.31641-.4687-.31641-.7734V8.46094l-.94922.77344c-.11719.09374-.24609.14062-.38672.14062-.16406 0-.30468-.07031-.42187-.21094l-.35157-.42187C.921875 8.625.875 8.50781.875 8.39062c0-.1875.070312-.33984.21094-.45703L9.73438.832031C10.1094.527344 10.5312.375 11 .375s.8906.152344 1.2656.457031l8.6485 7.101559zm-3.7266 6.50391V7.05469L11 1.99219l-6.1875 5.0625v7.38281h3.375v-3.6563c0-.3046.10547-.5624.31641-.7734.23437-.23436.5039-.35155.80859-.35155h3.375c.3047 0 .5625.11719.7734.35155.2344.211.3516.4688.3516.7734v3.6563h3.375z"
                ></path>
              </svg>
            </span>
            <span className="offcanvas__stikcy--toolbar__label">Home</span>
          </a>
        </li>
        <li className="offcanvas__stikcy--toolbar__list">
          <a
            className="offcanvas__stikcy--toolbar__btn"
            onClick={() => scrollToSection('trending')}
          >
            <span className="offcanvas__stikcy--toolbar__icon">
              <svg
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                width="18.51"
                height="17.443"
                viewBox="0 0 448 512"
              >
                <path d="M416 32H32A32 32 0 0 0 0 64v384a32 32 0 0 0 32 32h384a32 32 0 0 0 32-32V64a32 32 0 0 0-32-32zm-16 48v152H248V80zm-200 0v152H48V80zM48 432V280h152v152zm200 0V280h152v152z"></path>
              </svg>
            </span>
            <span className="offcanvas__stikcy--toolbar__label">Trends</span>
          </a>
        </li>
        <li className="offcanvas__stikcy--toolbar__list ">
          <a
            className="offcanvas__stikcy--toolbar__btn search__open--btn"
            data-offcanvas=""
            onClick={() => scrollToSection('products')}
          >
            <span className="offcanvas__stikcy--toolbar__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22.51"
                height="20.443"
                viewBox="0 0 512 512"
              >
                <path
                  d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z"
                  fill="none"
                  stroke="currentColor"
                  strokeMiterlimit="10"
                  strokeWidth="32"
                ></path>
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeMiterlimit="10"
                  strokeWidth="32"
                  d="M338.29 338.29L448 448"
                ></path>
              </svg>
            </span>
            <span className="offcanvas__stikcy--toolbar__label">Search</span>
          </a>
        </li>
        <li className="offcanvas__stikcy--toolbar__list">
          <a
            className="offcanvas__stikcy--toolbar__btn minicart__open--btn"
            onClick={() => scrollToSection('contact')}
            data-offcanvas=""
          >
            <span className="offcanvas__stikcy--toolbar__icon">
              <IconBuildingStore />
            </span>
            <span className="offcanvas__stikcy--toolbar__label">Contact</span>
          </a>
        </li>
        <li className="offcanvas__stikcy--toolbar__list">
          <a className="offcanvas__stikcy--toolbar__btn" href="/about">
            <span className="offcanvas__stikcy--toolbar__icon">
              <IconUserCircle />
            </span>
            <span className="offcanvas__stikcy--toolbar__label">About</span>
          </a>
        </li>
      </ul>
    </div>
  );
};
