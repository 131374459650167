//#region
import { useDocumentTitle } from '@mantine/hooks';
import Header from '../Sections/Header';
import Banner from '../Components/Banner';
import Navbar from '../Components/Navbar';
import Sections from '../Components/Sections';
import Products from '../Sections/Products';
import Sliders from '../Sections/Sliders';
import Trending from '../Sections/Trending';
import Filter from './Filter';
import Services from '../Sections/Services';
import Ratings from '../Sections/Ratings';
import Contact from '../Sections/Contact';
import Footer from '../Sections/Footer';
import Scripts from '../Components/Scripts';
import Toolbar from '../Sections/Toolbar';
import QuickView from '../Sections/QuickView';
import RatingForm from '../Components/RatingForm';
//#endregion

// eslint-disable-next-line
export default () => {
  useDocumentTitle('Krushi');
  return (
    <>
      <Header>
        <Banner show={true} message={'Welcome to Krushi Ladiez Boutique!'} />
        <Navbar loggedOut />
        <Toolbar />
      </Header>
      <Sections>
        <Sliders />
        <Trending />
        <Services />
        <Products />
        <Ratings />
        <RatingForm />
        <Contact />
      </Sections>
      <Filter />
      <Footer />
      <QuickView />
      <Scripts />
    </>
  );
};
