import Home from '../Containers/Home';
import Login from '../Containers/Login';
import Admin from '../Containers/Admin';
import About from '../Containers/About';
import NotFound from '../Containers/404';

export const RouteProvider = ({ route }: { route: any }) => {
  switch (route?.name) {
    case 'root':
      return <Home />;
    case 'login':
      return <Login />;
    case 'admin':
      return <Admin />;
    case 'about':
      return <About />;
    default:
      return <NotFound />;
  }
};
