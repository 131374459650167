import { Modal, Group, TextInput, Button, Title, Flex } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure, useShallowEffect } from '@mantine/hooks';

// eslint-disable-next-line
export default (props: any) => {
  const form = useForm({
    initialValues: { name: '' },
    validate: {
      name: (value) => (value ? null : 'Please enter category name!'),
    },
  });

  const onCloseCategoryModal = () => {
    const modal = document.getElementById('category-modal');
    modal && modal.classList.remove('is-visible');
  };
  const onSubmitCategoryAsync = () => {};

  return (
    <div className="modal" id="category-modal" data-animation="slideInUp">
      <div className="modal-dialog quickview__main--wrapper">
        <header className="modal-header quickview__header">
          <button
            className="close-modal quickview__close--btn"
            aria-label="close modal"
            onClick={onCloseCategoryModal}
          >
            ✕
          </button>
        </header>
        <div className="quickview__inner">
          <Title order={2} mb={14} mt={-12} c="#CE3F77">
            Add / Edit Categories
          </Title>
          <form onSubmit={form.onSubmit(onSubmitCategoryAsync)}>
            <Flex gap={20}>
              <TextInput
                mb={10}
                size="md"
                id="name"
                type="text"
                placeholder="Category"
                {...form.getInputProps('name')}
              />
              <Button
                size="md"
                color="#CE3F77"
                type="submit"
                className="secondary__btn"
              >
                Add
              </Button>
            </Flex>
          </form>
        </div>
      </div>
    </div>
  );
};
