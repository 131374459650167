import {
  Card,
  Title,
  Text,
  Button,
  ScrollArea,
  Group,
  Box,
  Flex,
  Avatar,
  Grid,
  Divider,
  Center,
  Loader,
  Rating,
} from '@mantine/core';
import { useShallowEffect, useViewportSize } from '@mantine/hooks';
import {
  IconCheck,
  IconEyeCheck,
  IconUserCircle,
  IconX,
} from '@tabler/icons-react';
import { useState } from 'react';
import { useAxios } from '../Utilities/axios';
import { onCallbackAsync, showError, showSuccess } from '../Utilities/_core';
import { useStateProvider } from '../Providers/State';
import { IconEyeCancel } from '@tabler/icons-react';

// eslint-disable-next-line
export default () => {
  const { width } = useViewportSize();

  const [ratings, setRatings] = useState([]);

  const { state, setState } = useStateProvider();

  const { loading, get } = useAxios(
    '/api/records/rating_master?order=created_at,desc'
  );

  const onOpenRatingModal = () => {
    const modal = document.getElementById('rating-modal');
    modal && modal.classList.add('is-visible');
  };

  useShallowEffect(() => {
    onCallbackAsync(async () => {
      const ratings = await get();
      if (
        ratings &&
        ratings.length > 0 &&
        ratings.length != state?.ratings.length
      ) {
        setRatings(ratings);
      }
    });
  }, [state?.ratings]);

  return (
    <div className={`mb-40 ${width < 768 ? 'px-5' : 'px-20'}`}>
      <Button
        mb={15}
        size="md"
        type="submit"
        color="#CE3F77"
        className="secondary__btn"
        onClick={onOpenRatingModal}
      >
        Add Testimonial
      </Button>
      <Card shadow="sm" padding="lg" radius="md" withBorder>
        {loading ? (
          <div style={{ height: '500px' }}>
            <Center>
              <Loader color="#CE3F77" />
            </Center>
          </div>
        ) : (
          <ScrollArea h={500}>
            {ratings?.map((rating: any) => {
              return <RatingItem rating={rating} key={rating.id} />;
            })}
          </ScrollArea>
        )}
      </Card>
    </div>
  );
};

const RatingItem = (props: any) => {
  const { state, setState } = useStateProvider();
  const { loading, put } = useAxios('/api/records/rating_master/');

  const onDeleteAsync = async (rating: any) => {
    const response = await put({
      url: rating.id,
      data: { is_deleted: !rating.is_deleted },
    });
    if (response) {
      showSuccess('Testimonial has been updated successfully!', <IconCheck />);
      setTimeout(() => {
        window.location.href = '/admin/#ratings';
      }, 1000);
    } else {
      showError('Something went wrong, please try later!', <IconX />);
    }
  };

  return (
    <div className="mb-10 mr-6">
      <Grid>
        <Grid.Col span={11}>
          <Group>
            <IconUserCircle color="#CE3F77" />
            <Title order={4}>{props.rating.name}</Title>
          </Group>
        </Grid.Col>
        <Grid.Col span={1}>
          <a
            className="product__card--action__btn float-right"
            onClick={() => onDeleteAsync(props.rating)}
          >
            {loading ? (
              <Loader color="#CE3F77" size={20} />
            ) : props.rating.is_deleted ? (
              <IconEyeCancel />
            ) : (
              <IconEyeCheck />
            )}
            <span className="visually-hidden">Delete</span>
          </a>
        </Grid.Col>
      </Grid>
      <Rating defaultValue={props.rating.rating} readOnly />
      <p className="text-justify">{props.rating.comments}</p>
      <Flex mt={-10} mb={10} justify="space-between">
        <Title order={5}>{props.rating.location}</Title>
        <Text className="font-md">{props.rating.created_at}</Text>
      </Flex>
      <Divider />
    </div>
  );
};
