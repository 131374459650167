import { Flex, Rating } from '@mantine/core';
import { useStateProvider } from '../Providers/State';
import { IconCurrencyRupee, IconMinus } from '@tabler/icons-react';

// eslint-disable-next-line
export default (props: any) => {
  const { state, setState } = useStateProvider();

  const category = state?.categories.find(
    (x: any) => x.id === state?.product?.category_master_id
  );

  const onCloseProductModal = () => {
    setState({ product: {} });
    const modal = document.getElementById('quick-view-modal');
    modal && modal.classList.remove('is-visible');
  };

  const image = state?.product?.image_master?.sort(
    (a: any, b: any) =>
      Number(new Date(b.created_at)) - Number(new Date(a.created_at))
  )[0];

  return (
    <div className="modal" id="quick-view-modal" data-animation="slideInUp">
      <div className="modal-dialog quickview__main--wrapper">
        <header className="modal-header quickview__header">
          <button
            className="close-modal quickview__close--btn"
            onClick={onCloseProductModal}
            aria-label="close modal"
          >
            ✕
          </button>
        </header>
        <div className="quickview__inner">
          <div className="row row-cols-lg-2 row-cols-md-2">
            <div className="col">
              <div className="quickview__gallery">
                <div className="product__media--right__horizontal">
                  <div className="product__media--preview__horizontal">
                    <div className="product__media--preview__items">
                      <div className="product__media--preview__items--link ">
                        {state?.product?.image_master ? (
                          <img
                            style={{ height: '360px' }}
                            className="product__media--preview__items--img"
                            src={image.source}
                            alt="product-media-img"
                          />
                        ) : (
                          <img
                            className="product__media--preview__items--img"
                            src={'https://placehold.co/400x400?text=Loading...'}
                            alt="product-media-img"
                          />
                        )}
                      </div>
                      {state?.product?.is_latest && (
                        <div className="product__badge">
                          <span className="product__badge--items sale">
                            New
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="w-144"></div>
              </div>
            </div>
            <div className="col">
              <div className="w-144"></div>
              <div className="quickview__info">
                <span className="product__card--meta__tag d-lg-mt-5 font-bld mb-10">
                  {category?.name}
                </span>
                <h2 className="product__details--info__title h4 mb-10">
                  <p>{state?.product?.name}</p>
                </h2>
                <div className="product__details--info__rating mb-10">
                  <Rating defaultValue={5} readOnly />
                </div>
                <div className="quantity__product--price mb-10">
                  <span className="current__price">
                    <Flex>
                      <IconCurrencyRupee size={18} />
                      <span style={{ marginTop: '-5px' }}>
                        {state?.product?.sale_price}
                      </span>
                    </Flex>
                  </span>{' '}
                  <span className="current__price">
                    <Flex>
                      <IconMinus size={18} />
                      <IconCurrencyRupee size={18} />
                      <span style={{ marginTop: '-5px' }}>
                        {state?.product?.mrp_price}
                      </span>
                    </Flex>
                  </span>
                </div>
                <p className="product__details--info__desc mb-15">
                  {state?.product?.description}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
