import Banner from '../Components/Banner';
import Navbar from '../Components/Navbar';
import ProductForm from '../Components/ProductForm';
import Scripts from '../Components/Scripts';
import Sections from '../Components/Sections';
import Filter from './Filter';
import Footer from '../Sections/Footer';
import Header from '../Sections/Header';
import Products from '../Sections/Products';
import QuickView from '../Sections/QuickView';
import { useDocumentTitle, useShallowEffect } from '@mantine/hooks';
import CategoryForm from '../Components/CategoryForm';
import { useAxios } from '../Utilities/axios';
import { onCallbackAsync, useSession } from '../Utilities/_core';
import { Center, Grid, Loader } from '@mantine/core';
import RatingForm from '../Components/RatingForm';
import RatingList from '../Components/RatingList';
import BannerForm from '../Components/BannerForm';

export default () => {
  useDocumentTitle('Krushi');
  const [token] = useSession('app_user', null);

  const { loading, get } = useAxios('/user/admin-login');

  useShallowEffect(() => {
    onCallbackAsync(async () => {
      if (token) {
        const user = await get();
        if (!user || (user && user.role === null)) {
          window.location.href = '/login';
        }
      } else {
        window.location.href = '/login';
      }
    });
  }, []);

  return (
    <>
      <Header>
        <Banner />
        <Navbar loggedIn />
      </Header>
      <Sections>
        <BannerForm />
        <Products isAdmin />
        <div className="mt-30" id="ratings">
          <Center>
            <h2 className="section__heading--maintitle text__secondary mt-10 mb-20">
              Testimonials
            </h2>
          </Center>
          <RatingForm isAdmin />
          <RatingList />
        </div>
      </Sections>
      <Filter isAdmin />
      <Footer />
      <QuickView />
      <CategoryForm />
      <ProductForm isAdmin />
      <Scripts />
    </>
  );
};
