import { IconCheck, IconSearch, IconX } from '@tabler/icons-react';
import { ActionIcon, Button, TextInput } from '@mantine/core';
import { showSuccess, showError } from '../Utilities/_core';
import { useAxios } from '../Utilities/axios';
import { useForm } from '@mantine/form';

// eslint-disable-next-line
export default () => {
  const { loading, put } = useAxios('/api/records/health/1');

  const form = useForm({
    initialValues: { banner_text: '' },
    validate: {
      banner_text: (value) => (value ? null : 'Please enter banner message!'),
    },
  });

  const onSubmitMessage = async (data: any) => {
    const response = await put({ data });
    if (response) {
      showSuccess(
        'Banner message has been updated successfully!',
        <IconCheck />
      );
      setTimeout(() => {
        window.location.href = '/admin';
      }, 1000);
    } else {
      showError('Something went wrong, please try later!', <IconX />);
    }
  };

  return (
    <div className="container-fluid">
      <div className="single__widget widget__bg bg__gray--color mt-10">
        <h2 className="widget__title position__relative h3">Banner Message</h2>
        <form
          className="widget__search--form"
          onSubmit={form.onSubmit(onSubmitMessage)}
        >
          <TextInput
            size="md"
            placeholder="Please enter the banner message"
            rightSection={
              <Button
                ml={-50}
                color="#CE3F77"
                variant="filled"
                type="submit"
                loading={loading}
                className="secondary__btn"
              >
                Submit
              </Button>
            }
            {...form.getInputProps('banner_text')}
          />
        </form>
      </div>
    </div>
  );
};
