import { useAxios } from '../Utilities/axios';

export const useApiService = (url: string) => {
  const { loading, data, get, post, put, del } = useAxios(url);

  const onGets = async () => await get();

  const onGet = async (id: any) => await get({ url: id });

  const onDel = async (id: any) => await del({ url: id });

  const onAdd = async (data: any) => await post({ data });

  const onUpd = async (id: any, data: any) => await put({ url: id, data });

  return {
    onGet,
    onAdd,
    onDel,
    onUpd,
    onGets,
    data,
    loading,
  };
};
