import ReactDOM from 'react-dom/client';
import { StateProvider } from './App/Providers/State';
// import * as serviceworker from './serviceworker';

import '@mantine/core/styles.css';
import '@mantine/dropzone/styles.css';
import '@mantine/notifications/styles.css';
import { App } from './App/Containers/_App';
import { MantineProvider, createTheme } from '@mantine/core';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const theme = createTheme({
  scale: 1.6,
});

root.render(
  <StateProvider>
    <MantineProvider defaultColorScheme="light" theme={theme}>
      <App />
    </MantineProvider>
  </StateProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceworker.unregister();
