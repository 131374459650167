import { Center, Loader, Pagination, Space, Text } from '@mantine/core';
import Filters from '../Components/Filters';
import Product from '../Components/Product';
import { useShallowEffect } from '@mantine/hooks';
import { useState } from 'react';
import { onCallbackAsync, scrollToSection } from '../Utilities/_core';
import { useAxios } from '../Utilities/axios';
import { useStateProvider } from '../Providers/State';
import { IconInfoCircle } from '@tabler/icons-react';
import { showNotification } from '@mantine/notifications';

// eslint-disable-next-line
export default (props: any) => {
  const { loading, get } = useAxios();
  const [results, setResults] = useState(0);
  const [products, setProducts] = useState([]);
  const { state, setState } = useStateProvider();

  let query =
    '/api/records/product_master?join=image_master&filter=is_deleted,eq,false';

  switch (state?.filter?.type) {
    case 'price':
      const price = state?.filter?.value.split('-');
      query = `${query}&filter=mrp_price,gt,${price[0]}&filter=sale_price,lt,${price[1]}`;
      break;
    case 'range':
      const range = state?.filter?.value.split('-');
      query = `${query}&filter=sale_price,gt,${range[0]}&filter=mrp_price,lt,${range[1]}`;
      break;
    case 'category':
      query = `${query}&filter=category_master_id,eq,${state?.filter?.value}`;
      break;
    case 'search':
      query = `${query}&filter=name,cs,${state?.filter?.value}`;
      break;
  }

  useShallowEffect(() => {
    onCallbackAsync(async () => {
      const products = await get({
        url: `${query}&order=updated_at,desc&page=${state?.page},6`,
      });
      if (products && products.results > 0) {
        setResults(products.results);
        setProducts(products.records);
      } else {
        showNotification({
          icon: <IconInfoCircle />,
          color: 'blue',
          title: 'Search Result',
          message: 'Filtered product has not been found!',
        });
      }
    });
  }, [state?.page, state?.filter]);

  const onOpenProductModal = () => {
    setState({
      product: {
        id: null,
        category_master_id: null,
        name: '',
        mrp_price: '',
        sale_price: '',
        is_latest: false,
        is_trending: false,
        is_deleted: false,
        description: '',
        rating: 5,
      },
    });
    const modal = document.getElementById('product-modal');
    modal && modal.classList.add('is-visible');
  };

  return (
    <>
      {props.isAdmin ? (
        <div style={{ height: 150 }}></div>
      ) : (
        <section
          id="products"
          className="product__section services__section--bg2 section--padding"
        >
          <div className="container-fluid">
            <div className="section__heading text-center">
              <h2 className="section__heading--maintitle text__secondary mb-10">
                Featured Products
              </h2>
              <p className="section__heading--desc">
                There are absolutely light-weighted, stunning, designer and
                quirky dresses that can steal the show as the bride walks down
                the aisle.
              </p>
            </div>
          </div>
        </section>
      )}
      <section
        className="shop__section services__section--bg2 section--padding"
        style={{ marginTop: '-8rem' }}
        id="products-admin"
      >
        <div className="container-fluid">
          <div className="row" style={{ marginTop: '-5rem' }}>
            <div className="col-xl-3 col-lg-4">
              <div className="shop__sidebar--widget widget__area d-md-none">
                <Filters isAdmin={props.isAdmin} />
              </div>
            </div>
            <div className="col-xl-9 col-lg-8">
              <div className="shop__header bg__gray--color d-flex align-items-center justify-content-between mb-30 single__widget widget__bg">
                <div className="product__view--mode d-flex align-items-center">
                  <div className="product__view--mode__list">
                    <div className="product__tab--one product__grid--column__buttons d-flex justify-content-center">
                      {props.isAdmin && (
                        <>
                          <button
                            className="price__filter--btn secondary__btn"
                            type="button"
                            onClick={onOpenProductModal}
                            style={{ height: '37px', fontSize: '14px' }}
                          >
                            <Text mt={-4}>Add Product</Text>
                          </button>
                          <Space w="md" />
                        </>
                      )}
                      <button
                        className="product__grid--column__buttons--icons active"
                        data-toggle="tab"
                        aria-label="product grid btn"
                        data-target="#product_grid"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13"
                          height="13"
                          viewBox="0 0 9 9"
                        >
                          <g transform="translate(-1360 -479)">
                            <rect
                              id="Rectangle_5725"
                              data-name="Rectangle 5725"
                              width="4"
                              height="4"
                              transform="translate(1360 479)"
                              fill="currentColor"
                            ></rect>
                            <rect
                              id="Rectangle_5727"
                              data-name="Rectangle 5727"
                              width="4"
                              height="4"
                              transform="translate(1360 484)"
                              fill="currentColor"
                            ></rect>
                            <rect
                              id="Rectangle_5726"
                              data-name="Rectangle 5726"
                              width="4"
                              height="4"
                              transform="translate(1365 479)"
                              fill="currentColor"
                            ></rect>
                            <rect
                              id="Rectangle_5728"
                              data-name="Rectangle 5728"
                              width="4"
                              height="4"
                              transform="translate(1365 484)"
                              fill="currentColor"
                            ></rect>
                          </g>
                        </svg>
                      </button>
                      <button
                        className="product__grid--column__buttons--icons"
                        data-toggle="tab"
                        aria-label="product list btn"
                        data-target="#product_list"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="16"
                          viewBox="0 0 13 8"
                        >
                          <g
                            id="Group_14700"
                            data-name="Group 14700"
                            transform="translate(-1376 -478)"
                          >
                            <g transform="translate(12 -2)">
                              <g id="Group_1326" data-name="Group 1326">
                                <rect
                                  id="Rectangle_5729"
                                  data-name="Rectangle 5729"
                                  width="3"
                                  height="2"
                                  transform="translate(1364 483)"
                                  fill="currentColor"
                                ></rect>
                                <rect
                                  id="Rectangle_5730"
                                  data-name="Rectangle 5730"
                                  width="9"
                                  height="2"
                                  transform="translate(1368 483)"
                                  fill="currentColor"
                                ></rect>
                              </g>
                              <g
                                id="Group_1328"
                                data-name="Group 1328"
                                transform="translate(0 -3)"
                              >
                                <rect
                                  id="Rectangle_5729-2"
                                  data-name="Rectangle 5729"
                                  width="3"
                                  height="2"
                                  transform="translate(1364 483)"
                                  fill="currentColor"
                                ></rect>
                                <rect
                                  id="Rectangle_5730-2"
                                  data-name="Rectangle 5730"
                                  width="9"
                                  height="2"
                                  transform="translate(1368 483)"
                                  fill="currentColor"
                                ></rect>
                              </g>
                              <g
                                id="Group_1327"
                                data-name="Group 1327"
                                transform="translate(0 -1)"
                              >
                                <rect
                                  id="Rectangle_5731"
                                  data-name="Rectangle 5731"
                                  width="3"
                                  height="2"
                                  transform="translate(1364 487)"
                                  fill="currentColor"
                                ></rect>
                                <rect
                                  id="Rectangle_5732"
                                  data-name="Rectangle 5732"
                                  width="9"
                                  height="2"
                                  transform="translate(1368 487)"
                                  fill="currentColor"
                                ></rect>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </button>
                      {props.isAdmin && (
                        <button
                          className="widget__filter--btn d-none d-md-flex align-items-center"
                          data-offcanvas=""
                        >
                          <svg
                            className="widget__filter--btn__icon"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="none"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="28"
                              d="M368 128h80M64 128h240M368 384h80M64 384h240M208 256h240M64 256h80"
                            ></path>
                            <circle
                              cx="336"
                              cy="128"
                              r="28"
                              fill="none"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="28"
                            ></circle>
                            <circle
                              cx="176"
                              cy="256"
                              r="28"
                              fill="none"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="28"
                            ></circle>
                            <circle
                              cx="336"
                              cy="384"
                              r="28"
                              fill="none"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="28"
                            ></circle>
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                {results > 0 && (
                  <p className="product__showing--count">
                    Showing {products.length} out of {results} items
                  </p>
                )}
              </div>
              <div className="shop__product--wrapper">
                {loading ? (
                  <div style={{ height: '1150px' }}>
                    <Center>
                      <Loader color="#CE3F77" />
                    </Center>
                  </div>
                ) : (
                  <div className="tab_content">
                    <div id="product_grid" className="tab_pane active show">
                      <div className="product__section--inner product__grid--inner">
                        <div className="row row-cols-xl-3 row-cols-lg-2 row-cols-md-3 row-cols-2 mb--n30">
                          {products?.map((product: any) => (
                            <Product.Item
                              product={product}
                              key={product.id}
                              index={product.id}
                              isAdmin={props.isAdmin}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                    <div id="product_list" className="tab_pane">
                      <div className="product__section--inner">
                        <div className="row row-cols-1 mb--n30">
                          {products?.map((product: any, index: number) => (
                            <Product.List
                              key={product.id}
                              index={product.id}
                              product={product}
                              isAdmin={props.isAdmin}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {Math.ceil(results / 6) > 1 && (
                  <div className="pagination__area bg__gray--color single__widget widget__bg">
                    <Center>
                      <Pagination
                        size="lg"
                        radius="xl"
                        value={state?.page}
                        boundaries={0}
                        color="#CE3F77"
                        total={Math.ceil(results / 6)}
                        onChange={(page) => {
                          setState({ page });
                          scrollToSection('products-admin');
                        }}
                      />
                    </Center>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
