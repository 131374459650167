import { Avatar, Button, Center, Loader, Rating } from '@mantine/core';
import { useShallowEffect, useViewportSize } from '@mantine/hooks';
import { useState } from 'react';
import { onCallbackAsync } from '../Utilities/_core';
import { useAxios } from '../Utilities/axios';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

// eslint-disable-next-line
export default () => {
  const [ratings, setRatings] = useState([]);

  const { loading, get } = useAxios(
    '/api/records/rating_master?filter=is_deleted,eq,false&order=created_at,desc'
  );

  const onOpenRatingModal = () => {
    const modal = document.getElementById('rating-modal');
    modal && modal.classList.add('is-visible');
  };

  const onOpenGoogleReview = () => {
    window.open('https://g.page/r/CRt7wGQJYntcEBM/review', '_blank');
  };

  useShallowEffect(() => {
    onCallbackAsync(async () => {
      const ratings = await get();
      if (ratings && ratings.length > 0) {
        setRatings(ratings);
        setTimeout(() => {
          new Swiper('.testimonial__swiper--activation', {
            slidesPerView: 3,
            loop: true,
            rewind: true,
            spaceBetween: 30,
            breakpoints: {
              1200: {
                slidesPerView: 3,
              },
              992: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              480: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              0: {
                slidesPerView: 1,
              },
            },
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
            modules: [Navigation],
          });
        }, 50);
      }
    });
  }, []);

  return (
    <section
      className="testimonial__section services__section--bg1 section--padding"
      id="ratings"
    >
      <div className="container">
        <div className="section__heading text-center mb-50">
          <h2 className="section__heading--maintitle text-white mb-10">
            Our Best Testimonial
          </h2>
          <p className="section__heading--desc text-white">
            Our clients are at the center of everything we do. We prioritize
            their individual preferences and strive to provide a seamless,
            personalized shopping experience.
          </p>
          <Center>
            <Button
              size="lg"
              color="#FFFFFF"
              c="dark"
              mt={30}
              onClick={onOpenGoogleReview}
            >
              Google review comments
            </Button>
          </Center>
        </div>
        {loading ? (
          <div style={{ height: '200px' }}>
            <Center>
              <Loader color="#FFFFFF" />
            </Center>
          </div>
        ) : (
          <div className="testimonial__inner testimonial__swiper--activation swiper">
            <div className="swiper-wrapper">
              {ratings?.map((rating: any) => {
                return <CustomerRating rating={rating} key={rating.id} />;
              })}
            </div>
            <div className="swiper__nav--btn swiper-button-next"></div>
            <div className="swiper__nav--btn swiper-button-prev"></div>
          </div>
        )}
      </div>
      <Center>
        <Button
          size="lg"
          color="#FFFFFF"
          c="dark"
          mt={30}
          onClick={onOpenRatingModal}
        >
          Add your review comments
        </Button>
      </Center>
    </section>
  );
};

const CustomerRating = (props: any) => {
  const { width } = useViewportSize();
  return (
    <div className="swiper-slide bg-white border-radius-medium h-152">
      <article className="testimonial__card text-center h-full">
        <div
          className="testimonial__card--thumbnail"
          style={{ marginTop: width < 768 ? '2rem' : '-2rem' }}
        >
          <Center>
            <Avatar size="lg" src={null} alt="no image here" color="#CE3F77" />
          </Center>
        </div>
        <div className="testimonial__content" style={{ marginTop: '-1rem' }}>
          <p className="testimonial__content--desc text-truncate">
            {props.rating.comments}
          </p>
          <Center>
            <Rating defaultValue={props.rating.rating} readOnly />
          </Center>
          <h3 className="testimonial__content--title">{props.rating.name}</h3>
          <h4 className="testimonial__content--subtitle text__secondary">
            {props.rating.location}
          </h4>
          <div className="h-5"></div>
        </div>
        <div
          className="testimonial__icon"
          style={{ marginLeft: '-2px', marginTop: '-2px' }}
        >
          <svg
            className="testimonial__icon--svg"
            data-name="Group 546"
            xmlns="http://www.w3.org/2000/svg"
            width="18.479"
            height="13.939"
            viewBox="0 0 21.479 18.939"
          >
            <path
              data-name="Path 131"
              d="M8.629,11.089A1.033,1.033,0,0,0,9.149,9.7L8.3,7.918a1.036,1.036,0,0,0-1.352-.5A11.937,11.937,0,0,0,3.206,9.841,9.053,9.053,0,0,0,.693,13.809,21.762,21.762,0,0,0,0,19.908v5.319a1.043,1.043,0,0,0,1.04,1.04h6.81a1.043,1.043,0,0,0,1.04-1.04v-6.81a1.043,1.043,0,0,0-1.04-1.04H4.592A7.306,7.306,0,0,1,5.8,13.168,6.586,6.586,0,0,1,8.629,11.089Z"
              transform="translate(0 -7.328)"
              fill="currentColor"
            ></path>
            <path
              data-name="Path 132"
              d="M79.312,11.172a1.033,1.033,0,0,0,.52-1.386l-.849-1.767a1.036,1.036,0,0,0-1.352-.5,12.552,12.552,0,0,0-3.725,2.408,9.248,9.248,0,0,0-2.53,3.985,21.47,21.47,0,0,0-.676,6.082v5.319a1.043,1.043,0,0,0,1.04,1.04h6.81a1.043,1.043,0,0,0,1.04-1.04V18.5a1.043,1.043,0,0,0-1.04-1.04H75.274a7.307,7.307,0,0,1,1.213-4.211A6.585,6.585,0,0,1,79.312,11.172Z"
              transform="translate(-58.45 -7.411)"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </article>
    </div>
  );
};
