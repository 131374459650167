import { useDocumentTitle } from '@mantine/hooks';
import Banner from '../Components/Banner';
import Navbar from '../Components/Navbar';
import Scripts from '../Components/Scripts';
import Sections from '../Components/Sections';
import Footer from '../Sections/Footer';
import Header from '../Sections/Header';
import { Card, Title, Text } from '@mantine/core';

// eslint-disable-next-line
export default () => {
  useDocumentTitle('Krushi');
  return (
    <>
      <Header>
        <Banner />
        <Navbar backHome />
      </Header>
      <Sections>
        <div className="login__section services__section--bg2 section--padding">
          <div className="container">
            <div className="section__heading text-center mb-30">
              <h2
                className="section__heading--maintitle text__secondary"
                style={{ marginTop: '-5rem' }}
              >
                About Us
              </h2>
              <p className="section__heading--desc">
                Krushi Ladiez Boutique is more than a place to shop for
                hand-embroidery bridal and groom attire; it's a celebration of
                art, culture, and the beauty of individuality.
              </p>
            </div>
            <Card
              shadow="sm"
              padding="lg"
              radius="md"
              withBorder
              mb={30}
              bg="#CE3F77"
              className="text-white"
            >
              <Title order={3} mb={10}>
                Boutique's Story :
              </Title>
              <Text size="md" mb={10} opacity={0.8} className="text-justify">
                <p className="text-white">
                  In 2008, Krushi Ladiez Boutique emerged to fulfill the
                  heartfelt requests of family members seeking custom-designed
                  attire. Driven by the satisfaction and glowing reviews from
                  our initial endeavors, our passion for the art of
                  hand-embroidery and bespoke designs led us to establish a
                  full-time profession. Over the years, our dedication and skill
                  have attracted a clientele that includes numerous high-profile
                  individuals from India and abroad who entrust us as their
                  personal designers.
                </p>
                <p className="text-white">
                  Our commitment to customer satisfaction goes beyond
                  expectations. We've successfully met even the most last-minute
                  requests, ensuring timely delivery directly to our customers.
                  With utmost effort and attention to detail, we take pride in
                  consistently achieving our clients' 100% satisfaction. At
                  Krushi, we thrive on the challenge of entertaining our
                  customers' needs, even accommodating requests on short notice.
                  Once a customer experiences our craftsmanship and personalized
                  service, they find no need to look for alternatives. Our
                  journey is a testament to the trust placed in us by those who
                  seek not just clothing but an unparalleled experience in
                  handcrafted elegance.
                </p>
                {/* Krushi Ladiez Boutique was founded with a deep-rooted passion
                for Indian craftsmanship and the art of hand-embroidery. Our
                journey began with a vision to celebrate the timeless beauty of
                Indian bridal and groom wear while infusing it with a touch of
                modern elegance. Inspired by the rich heritage of our culture,
                we set out to create bespoke bridal and groom ensembles that are
                not just clothing but cherished pieces of art. Our boutique's
                story is one of dedication to preserving tradition and
                craftsmanship while embracing the evolving desires of modern
                brides and grooms. */}
              </Text>
            </Card>
            <Card
              shadow="sm"
              padding="lg"
              radius="md"
              withBorder
              mb={30}
              bg="#CE3F77"
              className="text-white"
            >
              <Title order={3} mb={10}>
                Mission :
              </Title>
              <Text
                size="md"
                mb={10}
                c="white"
                opacity={0.8}
                className="text-justify"
              >
                Our mission at Krushi Ladiez Boutique is to be the foremost
                destination for discerning brides seeking hand-embroidered and
                custom-designed Indian bridal and grooms wear. We are committed
                to crafting bridal and groom attire that encapsulates the
                essence of tradition and individuality. Every garment that
                leaves our boutique is a testament to our dedication to quality,
                artistry, and client satisfaction. We aim to make every dream
                attire a reality by providing exceptional designs and
                personalized service.
              </Text>
            </Card>
            <Card
              shadow="sm"
              padding="lg"
              radius="md"
              withBorder
              mb={30}
              bg="#CE3F77"
              className="text-white"
            >
              <Title order={3} mb={10}>
                Quality :
              </Title>
              <Text
                size="md"
                mb={10}
                c="white"
                opacity={0.8}
                className="text-justify"
              >
                We are unwavering in our commitment to delivering top-quality
                products. Our hand-embroidery and craftsmanship adhere to the
                highest standards, ensuring that every piece is a work of art.
              </Text>
            </Card>
            <Card
              shadow="sm"
              padding="lg"
              radius="md"
              withBorder
              mb={30}
              bg="#CE3F77"
              className="text-white"
            >
              <Title order={3} mb={10}>
                Tradition :
              </Title>
              <Text
                size="md"
                mb={10}
                c="white"
                opacity={0.8}
                className="text-justify"
              >
                We hold Indian traditions and heritage close to our hearts. Our
                designs are a tribute to the timeless beauty of Indian bridal
                and groom wear.
              </Text>
            </Card>
            <Card
              shadow="sm"
              padding="lg"
              radius="md"
              withBorder
              mb={30}
              bg="#CE3F77"
              className="text-white"
            >
              <Title order={3} mb={10}>
                Creativity :
              </Title>
              <Text
                size="md"
                mb={10}
                c="white"
                opacity={0.8}
                className="text-justify"
              >
                We value creativity and innovation. Our dedication to crafting
                custom-designed bridal wear means that we're always exploring
                new ideas and concepts to stay at the forefront of bridal
                fashion
              </Text>
            </Card>
            <Card
              shadow="sm"
              padding="lg"
              radius="md"
              withBorder
              mb={30}
              bg="#CE3F77"
              className="text-white"
            >
              <Title order={3} mb={10}>
                Client-Centric :
              </Title>
              <Text
                size="md"
                mb={10}
                c="white"
                opacity={0.8}
                className="text-justify"
              >
                Our clients are at the center of everything we do. We prioritize
                their individual preferences and strive to provide a seamless,
                personalized shopping experience.
              </Text>
            </Card>
            <Card
              shadow="sm"
              padding="lg"
              radius="md"
              withBorder
              mb={30}
              bg="#CE3F77"
              className="text-white"
            >
              <Title order={3} mb={10}>
                Ethical Practices :
              </Title>
              <Text
                size="md"
                mb={10}
                c="white"
                opacity={0.8}
                className="text-justify"
              >
                We uphold ethical and sustainable practices in our business
                operations, from sourcing materials to ensuring fair treatment
                of our artisans.
              </Text>
            </Card>
          </div>
        </div>
      </Sections>
      <Footer />
      <Scripts />
    </>
  );
};
