import {
  Button,
  Center,
  Group,
  NumberInput,
  Rating,
  TextInput,
  Textarea,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useViewportSize } from '@mantine/hooks';
import { useAxios } from '../Utilities/axios';
import { showError, showSuccess } from '../Utilities/_core';
import { IconCheck } from '@tabler/icons-react';
import { IconX } from '@tabler/icons-react';

// eslint-disable-next-line
export default (props: any) => {
  const { width } = useViewportSize();
  const { loading, post } = useAxios('/api/records/rating_master');

  const initialValues = {
    name: '',
    location: '',
    comments: '',
    rating: '',
    is_deleted: !props.isAdmin,
  };

  const form = useForm({
    initialValues,
    validateInputOnBlur: ['name', 'location', 'comments', 'rating'],
    validate: {
      name: (value) => (value ? null : 'Please enter name!'),
      location: (value) => (value ? null : 'Please enter location!'),
      rating: (value) => (value ? null : 'Please enter rating!'),
      comments: (value) => (value ? null : 'Please enter comments!'),
    },
  });

  const onCloseRatingModal = () => {
    const modal = document.getElementById('rating-modal');
    modal && modal.classList.remove('is-visible');
  };

  const onSubmitAsync = async (data: any) => {
    data['id'] = URL.createObjectURL(new Blob([])).slice(-36); // UUID
    const response = await post({ data });
    if (response) {
      showSuccess(
        props.isAdmin
          ? 'Testimonial has been added successfully!'
          : 'Your review comments has been posted and it will be added soon!',
        <IconCheck />
      );
      if (props.isAdmin) {
        setTimeout(() => {
          window.location.href = '/admin/#ratings';
        }, 1000);
      }
    } else {
      showError('Something went wrong, please try later!', <IconX />);
    }
    form.setValues(initialValues);
    form.resetDirty(initialValues);
    onCloseRatingModal();
  };

  return (
    <div className="modal" id="rating-modal" data-animation="slideInUp">
      <div
        className="modal-dialog quickview__main--wrapper"
        style={{ width: `${width < 768 ? '95%' : '450px'}` }}
      >
        <header className="modal-header quickview__header">
          <button
            className="close-modal quickview__close--btn"
            aria-label="close modal"
            onClick={onCloseRatingModal}
          >
            ✕
          </button>
        </header>
        <div className="quickview__inner">
          <Title order={3} mb={15}>
            Testimonial Form
          </Title>
          <form onSubmit={form.onSubmit(onSubmitAsync)}>
            <Textarea
              mb={15}
              size="lg"
              placeholder="Review Comments"
              {...form.getInputProps('comments')}
            />
            <Group grow wrap="nowrap">
              <Center>
                <Rating mt={-10} readOnly {...form.getInputProps('rating')} />
              </Center>
              <NumberInput
                mt={-5}
                mb={10}
                size="lg"
                maxLength={1}
                placeholder="Rating"
                min={0}
                max={5}
                {...form.getInputProps('rating')}
              />
            </Group>
            <TextInput
              mb={10}
              size="lg"
              type="text"
              placeholder="Customer Name"
              {...form.getInputProps('name')}
            />
            <TextInput
              mb={10}
              size="lg"
              type="text"
              placeholder="Location"
              {...form.getInputProps('location')}
            />
            <Group grow wrap="nowrap">
              <Button color="gray" variant="filled">
                Close
              </Button>
              <Button
                type="submit"
                variant="filled"
                color="#CE3F77"
                loading={loading}
              >
                Submit
              </Button>
            </Group>
          </form>
        </div>
      </div>
    </div>
  );
};
