import axios from 'axios';
import { useState } from 'react';
import { API_BASE_URL, useSession, encode } from '../Utilities/_core';

export const useLoginService = () => {
  const [loading, setLoading] = useState(false);
  const [user, setToken] = useSession('app_user', null);

  const onLoginAsync = async (user: any) => {
    setLoading(true);
    const url = API_BASE_URL + '/user/admin-login';
    const token = encode(`${user.email}:${user.password}`);
    try {
      const res = await axios.get(url, {
        headers: { Authorization: `Basic ${token}` },
      });
      setToken(token);
      setLoading(false);
      return res?.data;
    } catch (error) {
      setLoading(false);
      return null;
    }
  };

  return { loading, onLoginAsync };
};
