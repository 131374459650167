import { Button, TextInput, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconBrandInstagram, IconCheck, IconX } from '@tabler/icons-react';
import { showSuccess, showError } from '../Utilities/_core';
import { useAxios } from '../Utilities/axios';

// eslint-disable-next-line
export default () => {
  const { loading, post } = useAxios('/email/contact');

  const initialValues = {
    first_name: '',
    last_name: '',
    phone_number: '',
    email: '',
    message: '',
  };

  const form = useForm({
    initialValues,
    validateInputOnBlur: [
      'first_name',
      'last_name',
      'phone_number',
      'email',
      'message',
    ],
    validate: {
      first_name: (value) => (value ? null : 'Please enter first name!'),
      last_name: (value) => (value ? null : 'Please enter last name!'),
      phone_number: (value) => (value ? null : 'Please enter phone number!'),
      email: (value) => (value ? null : 'Please enter email!'),
      message: (value) => (value ? null : 'Please enter message!'),
    },
  });

  const onSubmitAsync = async (data: any) => {
    const response = await post({ data });
    if (response) {
      showSuccess('Message has been sent successfully!', <IconCheck />);
    } else {
      showError('Something went wrong, please try later!', <IconX />);
    }
    form.setValues(initialValues);
    form.resetDirty(initialValues);
  };

  return (
    <section
      className="contact__section services__section--bg2 section--padding"
      id="contact"
    >
      <div className="container">
        <div className="section__heading text-center mb-40">
          <h2 className="section__heading--maintitle text__secondary mb-10">
            Get In Touch
          </h2>
          <p className="section__heading--desc">
            Krushi Ladiez Boutique is more than a place to shop for
            hand-embroidery bridal and groom attire; it's a celebration of art,
            culture, and the beauty of individuality.
          </p>
        </div>
        <div className="main__contact--area">
          <div className="row align-items-center row-md-reverse">
            <div className="col-lg-5">
              <div className="contact__info border-radius-10">
                <div className="contact__info--items">
                  <h3 className="contact__info--content__title text-white mb-15">
                    Contact Us
                  </h3>
                  <div className="contact__info--items__inner d-flex">
                    <div className="contact__info--icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="31.568"
                        height="31.128"
                        viewBox="0 0 31.568 31.128"
                      >
                        <path
                          id="ic_phone_forwarded_24px"
                          d="M26.676,16.564l7.892-7.782L26.676,1V5.669H20.362v6.226h6.314Zm3.157,7a18.162,18.162,0,0,1-5.635-.887,1.627,1.627,0,0,0-1.61.374l-3.472,3.424a23.585,23.585,0,0,1-10.4-10.257l3.472-3.44a1.48,1.48,0,0,0,.395-1.556,17.457,17.457,0,0,1-.9-5.556A1.572,1.572,0,0,0,10.1,4.113H4.578A1.572,1.572,0,0,0,3,5.669,26.645,26.645,0,0,0,29.832,32.128a1.572,1.572,0,0,0,1.578-1.556V25.124A1.572,1.572,0,0,0,29.832,23.568Z"
                          transform="translate(-3 -1)"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                    <div className="contact__info--content">
                      <p className="contact__info--content__desc text-white">
                        Contact us with this number <br />
                        <a href="tel:+91-998-646-1111">+91-998-646-1111</a>
                        {/* <a href="tel:++01234-5688765">+01234-5688765</a> */}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="contact__info--items">
                  <h3 className="contact__info--content__title text-white mb-15">
                    Email Addresses
                  </h3>
                  <div className="contact__info--items__inner d-flex">
                    <div className="contact__info--icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="31.57"
                        height="31.13"
                        viewBox="0 0 31.57 31.13"
                      >
                        <path
                          id="ic_email_24px"
                          d="M30.413,4H5.157C3.421,4,2.016,5.751,2.016,7.891L2,31.239c0,2.14,1.421,3.891,3.157,3.891H30.413c1.736,0,3.157-1.751,3.157-3.891V7.891C33.57,5.751,32.149,4,30.413,4Zm0,7.783L17.785,21.511,5.157,11.783V7.891l12.628,9.728L30.413,7.891Z"
                          transform="translate(-2 -4)"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                    <div className="contact__info--content">
                      <p className="contact__info--content__desc text-white">
                        <a href="mailto:krushiladiezboutique@gmail.com">
                          krushiladiezboutique@gmail.com
                        </a>
                        <br />
                        <a href="mailto:support@krushiladiezboutique.in">
                          support@krushiladiezboutique.in
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="contact__info--items">
                  <h3 className="contact__info--content__title text-white mb-15">
                    Boutique Locations
                  </h3>
                  <div className="contact__info--items__inner d-flex">
                    <div className="contact__info--icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="31.57"
                        height="31.13"
                        viewBox="0 0 31.57 31.13"
                      >
                        <path
                          id="ic_account_balance_24px"
                          d="M5.323,14.341V24.718h4.985V14.341Zm9.969,0V24.718h4.985V14.341ZM2,32.13H33.57V27.683H2ZM25.262,14.341V24.718h4.985V14.341ZM17.785,1,2,8.412v2.965H33.57V8.412Z"
                          transform="translate(-2 -1)"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                    <div className="contact__info--content">
                      <p className="contact__info--content__desc text-white">
                        433, K.R. Road, 14th Cross, Shastrinagar,
                        <br />
                        Bangalore-560028.
                      </p>
                    </div>
                  </div>
                  <div className="contact__info--items__inner d-flex">
                    <div className="contact__info--icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="31.57"
                        height="31.13"
                        viewBox="0 0 31.57 31.13"
                      >
                        <path
                          id="ic_account_balance_24px"
                          d="M5.323,14.341V24.718h4.985V14.341Zm9.969,0V24.718h4.985V14.341ZM2,32.13H33.57V27.683H2ZM25.262,14.341V24.718h4.985V14.341ZM17.785,1,2,8.412v2.965H33.57V8.412Z"
                          transform="translate(-2 -1)"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                    <div className="contact__info--content">
                      <p className="contact__info--content__desc text-white">
                        62, 80 Feet Rd, Mysore Bank Colony, Nagendra Block,
                        Banashankari 1st Stage, Banashankari, Opp Seetha Circle
                        bus stop, Above GoColors,
                        <br />
                        Bengaluru-560050.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="contact__info--items">
                  <h3 className="contact__info--content__title text-white mb-15">
                    Follow Us
                  </h3>
                  <ul className="contact__info--social d-flex">
                    <li className="contact__info--social__list">
                      <a
                        className="contact__info--social__icon"
                        target="_blank"
                        href="https://www.facebook.com/profile.php?id=100064053009873&mibextid=ZbWKwL"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="7.667"
                          height="16.524"
                          viewBox="0 0 7.667 16.524"
                        >
                          <path
                            data-name="Path 237"
                            d="M967.495,353.678h-2.3v8.253h-3.437v-8.253H960.13V350.77h1.624v-1.888a4.087,4.087,0,0,1,.264-1.492,2.9,2.9,0,0,1,1.039-1.379,3.626,3.626,0,0,1,2.153-.6l2.549.019v2.833h-1.851a.732.732,0,0,0-.472.151.8.8,0,0,0-.246.642v1.719H967.8Z"
                            transform="translate(-960.13 -345.407)"
                            fill="currentColor"
                          ></path>
                        </svg>
                        <span className="visually-hidden">Facebook</span>
                      </a>
                    </li>
                    <li className="contact__info--social__list">
                      <a
                        className="contact__info--social__icon"
                        target="_blank"
                        href="https://www.instagram.com/krushiladiezboutique/"
                      >
                        <IconBrandInstagram />
                        <span className="visually-hidden">Instagram</span>
                      </a>
                    </li>
                    <li className="contact__info--social__list">
                      <a
                        className="contact__info--social__icon"
                        target="_blank"
                        href="https://www.youtube.com/channel/UC5XAGn6PQa3Z8TORGVO2iOg"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16.49"
                          height="11.582"
                          viewBox="0 0 16.49 11.582"
                        >
                          <path
                            data-name="Path 321"
                            d="M967.759,1365.592q0,1.377-.019,1.717-.076,1.114-.151,1.622a3.981,3.981,0,0,1-.245.925,1.847,1.847,0,0,1-.453.717,2.171,2.171,0,0,1-1.151.6q-3.585.265-7.641.189-2.377-.038-3.387-.085a11.337,11.337,0,0,1-1.5-.142,2.206,2.206,0,0,1-1.113-.585,2.562,2.562,0,0,1-.528-1.037,3.523,3.523,0,0,1-.141-.585c-.032-.2-.06-.5-.085-.906a38.894,38.894,0,0,1,0-4.867l.113-.925a4.382,4.382,0,0,1,.208-.906,2.069,2.069,0,0,1,.491-.755,2.409,2.409,0,0,1,1.113-.566,19.2,19.2,0,0,1,2.292-.151q1.82-.056,3.953-.056t3.952.066q1.821.067,2.311.142a2.3,2.3,0,0,1,.726.283,1.865,1.865,0,0,1,.557.49,3.425,3.425,0,0,1,.434,1.019,5.72,5.72,0,0,1,.189,1.075q0,.095.057,1C967.752,1364.1,967.759,1364.677,967.759,1365.592Zm-7.6.925q1.49-.754,2.113-1.094l-4.434-2.339v4.66Q958.609,1367.311,960.156,1366.517Z"
                            transform="translate(-951.269 -1359.8)"
                            fill="currentColor"
                          ></path>
                        </svg>
                        <span className="visually-hidden">Youtube</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="contact__form">
                <form
                  className="contact__form--inner"
                  onSubmit={form.onSubmit(onSubmitAsync)}
                >
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="contact__form--list mb-20">
                        <label
                          className="contact__form--label"
                          htmlFor="input1"
                        >
                          First Name
                          <span className="contact__form--label__star">*</span>
                        </label>
                        <TextInput
                          mb={10}
                          size="lg"
                          type="text"
                          placeholder="First Name"
                          {...form.getInputProps('first_name')}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="contact__form--list mb-20">
                        <label
                          className="contact__form--label"
                          htmlFor="input2"
                        >
                          Last Name
                          <span className="contact__form--label__star">*</span>
                        </label>
                        <TextInput
                          mb={10}
                          size="lg"
                          type="text"
                          placeholder="Last Name"
                          {...form.getInputProps('last_name')}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="contact__form--list mb-20">
                        <label
                          className="contact__form--label"
                          htmlFor="input3"
                        >
                          Phone Number
                          <span className="contact__form--label__star">*</span>
                        </label>
                        <TextInput
                          mb={10}
                          size="lg"
                          type="text"
                          placeholder="Phone Number"
                          {...form.getInputProps('phone_number')}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="contact__form--list mb-20">
                        <label
                          className="contact__form--label"
                          htmlFor="input4"
                        >
                          Email
                          <span className="contact__form--label__star">*</span>
                        </label>
                        <TextInput
                          mb={10}
                          size="lg"
                          type="text"
                          placeholder="Email"
                          {...form.getInputProps('email')}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="contact__form--list mb-10">
                        <label
                          className="contact__form--label"
                          htmlFor="input5"
                        >
                          Write Your Message
                          <span className="contact__form--label__star">*</span>
                        </label>
                        <Textarea
                          mb={15}
                          size="lg"
                          placeholder="Write Your Message"
                          {...form.getInputProps('message')}
                        />
                      </div>
                    </div>
                  </div>
                  <Button
                    size="lg"
                    type="submit"
                    variant="filled"
                    color="#CE3F77"
                    fullWidth
                    loading={loading}
                    className="contact__form--btn secondary__btn"
                  >
                    Submit Now
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
