//#region
import $ from 'jquery';
import { Router } from './router';
import { Base64 } from './Base64';
import { showNotification } from '@mantine/notifications';
import { createContext, useContext, useState, useEffect } from 'react';
//#endregion

const base64 = new Base64();

const STORAGE_PREFIX = '5t0re.';

export const API_BASE_URL =
  process.env.NODE_ENV === 'development'
    ? // 'http://127.0.0.1:8080'
      'https://api-test.krushiladiezboutique.in'
    : 'https://api-prod.krushiladiezboutique.in';

export const encode = (text: string) => base64.encode(text);

export const decode = (text: string) => base64.decode(text);

export const StateContext = createContext<TState | null>(null);

export const showError = (message: string, icon?: any) => {
  showNotification({
    icon,
    message,
    color: 'red',
    title: 'Error',
  });
};

export const showSuccess = (message: string, icon?: any) => {
  showNotification({
    icon,
    message,
    color: 'green',
    title: 'Success',
  });
};

export const onCallbackAsync = (onCallback: any) => {
  return onCallback();
};

export const useAppState = () => {
  const [loading, setLoading] = useState(true);
  const [email, setEmailSession] = useSession('user_email');
  const { state, setState } = useContext(StateContext) as TState;

  useEffect(() => {
    setLoading(false);
  }, [loading]);

  const onCallbackState = (onCallback: any) => {
    onLoadState();
    return onCallback();
  };

  const onLoadState = async () => {
    if (!email) return onLogoutState();
    // const user = await onUsersAsync(email);
    // setState({ isLogged: true, email, user });
  };

  const onLoginState = (email: string, token?: string) => {
    setEmailSession(email);
    setState({ isLogged: true, email });
    Router.navigate('root');
  };

  const onLogoutState = () => {
    setEmailSession(null);
    setState({ isLogged: false, email: null });
    window.location.href = '/login';
  };

  return {
    state,
    loading,
    setState,
    onLoadState,
    onLoginState,
    onLogoutState,
    onCallbackState,
  };
};

export const useSession = (key: any, defaultValue?: any) => {
  const getValue = (key: any, defaultValue?: any) => {
    const stored = localStorage.getItem(STORAGE_PREFIX + key);
    return !stored ? defaultValue : JSON.parse(stored);
  };

  const [value] = useState(getValue(key, defaultValue));

  const setValue = (value: any) => {
    localStorage.setItem(STORAGE_PREFIX + key, JSON.stringify(value));
  };
  return [value, setValue];
};

export const scrollToSection = (section: string) => {
  var position = $(`#${section}`).offset()?.top;
  if (position) {
    $('html, body').animate(
      {
        scrollTop: position - 100,
      },
      2000
    );
  }
};
