import { AppShell } from '@mantine/core';
import { RouteProvider } from '../Providers/Router';
import { Notifications } from '@mantine/notifications';
import { useRouter } from '../Utilities/router';
import { useViewportSize } from '@mantine/hooks';

export const App = () => {
  const route = useRouter();
  const { width } = useViewportSize();

  return (
    <AppShell disabled>
      <Notifications position="top-center" mt={width < 768 ? 45 : 90} />
      <RouteProvider route={route} />
    </AppShell>
  );
};
