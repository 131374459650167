import {
  IconEdit,
  IconTrash,
  IconChevronRight,
  IconCurrencyRupee,
  IconMinus,
  IconCheck,
  IconX,
} from '@tabler/icons-react';
import { Center, Flex, Image, Loader, Rating } from '@mantine/core';
import { useStateProvider } from '../Providers/State';
import { useAxios } from '../Utilities/axios';
import { showSuccess, showError } from '../Utilities/_core';

const Actions = (props: any) => {
  const { loading, put } = useAxios('/api/records/product_master/');
  const { state, setState } = useStateProvider();

  const onDeleteProductAsync = async (id: string) => {
    const response = await put({ url: id, data: { is_deleted: true } });
    if (response > 0) {
      setTimeout(() => {
        window.location.href = '/admin/#products';
      }, 1000);
      showSuccess('Product has been deleted successfully!', <IconCheck />);
    } else {
      showError('Something went wrong, please try later!', <IconX />);
    }
  };

  const onOpenProductModal = (productSelected: any, modalId: string) => {
    productSelected.mrp_price = Math.round(productSelected.mrp_price);
    productSelected.sale_price = Math.round(productSelected.sale_price);
    setState({ product: productSelected });
    const modal = document.getElementById(modalId);
    modal && modal.classList.add('is-visible');
  };

  return (
    <ul className="product__card--action d-flex align-items-center justify-content-center">
      {props.isAdmin ? (
        <>
          {loading ? (
            <li className="product__card--action__list">
              <a className="product__card--action__btn">
                <Loader color="#CE3F77" size={24} />
                <span className="visually-hidden">Delete</span>
              </a>
            </li>
          ) : (
            <>
              <li className="product__card--action__list">
                <a
                  className="product__card--action__btn"
                  onClick={() => onDeleteProductAsync(props.product.id)}
                >
                  <IconTrash />
                  <span className="visually-hidden">Delete</span>
                </a>
              </li>
              <li className="product__card--action__list">
                <a
                  className="product__card--action__btn"
                  onClick={() =>
                    onOpenProductModal(props.product, 'product-modal')
                  }
                >
                  <IconEdit />
                  <span className="visually-hidden">Edit</span>
                </a>
              </li>
            </>
          )}
        </>
      ) : (
        <li className="product__card--action__list">
          <a
            className="product__card--action__btn"
            onClick={() =>
              onOpenProductModal(props.product, 'quick-view-modal')
            }
            title="Quick View"
          >
            <IconChevronRight />
            <span className="visually-hidden">Quick View</span>
          </a>
        </li>
      )}
    </ul>
  );
};

const Item = (props: any) => {
  const { state } = useStateProvider();
  const category = state?.categories.find(
    (x: any) => x.id === props.product?.category_master_id
  );

  const image = props.product?.image_master?.sort(
    (a: any, b: any) =>
      Number(new Date(b.created_at)) - Number(new Date(a.created_at))
  )[0];

  return (
    <div className="col custom-col-2 mb-30">
      <article className="product__card bg__gray--color product__widget widget__bg">
        <div className="product__card--thumbnail">
          <div className="product__card--thumbnail__link display-block">
            <Image
              className="product__card--thumbnail__img img-product-size"
              src={image?.source}
              fallbackSrc="https://placehold.co/400x400?text=Loading..."
            />
          </div>
          <Actions isAdmin={props.isAdmin} product={props.product} />
          {props.product?.is_latest && (
            <div className="product__badge">
              <span className="product__badge--items">NEW</span>
            </div>
          )}
        </div>
        <div className="product__card--content text-center">
          <span className="product__card--meta__tag font-bld">
            {category?.name}
          </span>
          <h3 className="product__card--title">
            <p>{props.product?.name}</p>
          </h3>
          <div className="product__card--price">
            <span className="current__price">
              <Flex>
                <IconCurrencyRupee size={18} />
                <span style={{ marginTop: '-5px' }}>
                  {Math.round(props.product?.sale_price)}
                </span>
              </Flex>
            </span>{' '}
            <span className="current__price">
              <Flex>
                <IconMinus size={18} />
                <IconCurrencyRupee size={18} />
                <span style={{ marginTop: '-5px' }}>
                  {Math.round(props.product?.mrp_price)}
                </span>
              </Flex>
            </span>
          </div>
          <div className="rating product__card--list__rating">
            <Center>
              <Rating defaultValue={5} readOnly />
            </Center>
          </div>
        </div>
      </article>
    </div>
  );
};

const List = (props: any) => {
  const { state } = useStateProvider();
  const category = state?.categories.find(
    (x: any) => x.id == props.product?.category_master_id
  );

  const image = props.product?.image_master?.sort(
    (a: any, b: any) =>
      Number(new Date(b.created_at)) - Number(new Date(a.created_at))
  )[0];

  return (
    <div className="col mb-30" key={props.index}>
      <article className=" product__card product__card--list d-flex bg__gray--color product__widget">
        <div className="product__card--thumbnail product__card--list__thumbnail">
          <div className="product__card--thumbnail__link display-block">
            <Image
              className="product__card--thumbnail__img img-product-size"
              src={image?.source}
              fallbackSrc="https://placehold.co/400x400?text=Loading..."
            />
          </div>
          <Actions isAdmin={props.isAdmin} product={props.product} />
          {props.product?.is_latest && (
            <div className="product__badge">
              <span className="product__badge--items">NEW</span>
            </div>
          )}
        </div>
        <div className="product__card--content product__card--list__content">
          <span className="product__card--meta__tag d-lg-mt-5 font-bld">
            {category?.name}
          </span>
          <h3 className="product__card--title">
            <p>{props.product?.name}</p>
          </h3>
          <div className="product__card--price">
            <span className="current__price">
              <Flex>
                <IconCurrencyRupee size={18} />
                <span style={{ marginTop: '-5px' }}>
                  {Math.round(props.product?.sale_price)}
                </span>
              </Flex>
            </span>{' '}
            <span className="current__price">
              <Flex>
                <IconMinus size={18} />
                <IconCurrencyRupee size={18} />
                <span style={{ marginTop: '-5px' }}>
                  {Math.round(props.product?.mrp_price)}
                </span>
              </Flex>
            </span>
          </div>
          <div className="rating product__card--list__rating">
            <Rating defaultValue={5} readOnly />
          </div>
          <p className="product__list--items__content--desc d-lg-none mb-20">
            {props.product?.description}
          </p>
        </div>
      </article>
    </div>
  );
};

export default { Item, List };
